import * as yup from "yup";

export const registrationValidation = yup.object().shape({
  username: yup.string().required("Username is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  fullname: yup.string().required("Full Name is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"), // Only checks for length
});

export const loginValidation = yup.object().shape({
  username: yup.string().required("Username/Email is required"),
  password: yup.string().required("Password is required"),
});

export const passwordValidation = yup.object().shape({
  current: yup.string().required("Current password is required"),
  newpass: yup
    .string()
    .required("New password is required")
    .matches(
      /^(?=.*\d)(?=.*\W)(?!.*\s).{8,}$/,
      "Password must contain at least one digit, one special character, and be at least 8 characters long"
    ),
  confirmpass: yup
    .string()
    .required("Confirm new password is required")
    .oneOf([yup.ref("newpass")], "Passwords must match"),
});

export const paymentAmount = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required")
    .test(
      "minimum-amount",
      "Amount must be at least 500",
      (value) => value >= 500
    )
    .test(
      "maximum-amount",
      "Amount cannot exceed #10,000",
      (value) => value <= 10000
    ),
});

export const validateEducational = yup.object().shape({
  pinType: yup.string().required("Pin/Card type is required"),
});

export const validatePurchaseData = yup.object().shape({
  network: yup.string().required("Network Provider is required"),
  type: yup.string().required("Bundle type is required"),
  bundle: yup.string().required("Data Bundle is required"),
  number: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d{11}$/, "Phone number must be exactly 11 digits"),
});

// export const BVNValidation = yup.object().shape({
//   bvn: yup
// })
