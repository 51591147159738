import { Box, Button, Typography, useTheme } from "@mui/material";
import { InputText } from "../TextFields/InputText";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setSubmitted,
  setSuccess,
  setMessage,
  clearMessage,
} from "../../utils/formSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link, useNavigate } from "react-router-dom";
import { registrationValidation } from "../../utils/validationScheme";
import axiosInstances from "../../hooks/axiosInstances";

export const Signup = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { isLoading } = useSelector((state: any) => state.form);

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      fullname: "",
      password: "",
    },
    validationSchema: registrationValidation,
    onSubmit: (values) => {
      dispatch(setLoading(true));
      axiosInstances
        .post(`/users`, values)
        .then((response: any) => {
          dispatch(setMessage(response.data.message));
          dispatch(setSuccess());
          dispatch(setSubmitted());
          dispatch(setLoading(false));
          formik.resetForm();
          setTimeout(() => {
            navigate("/login");
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          dispatch(setMessage(error.response.data.error));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });
  return (
    <>
      <Box pb="30px" textAlign="center">
        <Typography variant="h6" fontWeight="bold" component="div">
          Sign Up
        </Typography>
        <Typography variant="body1">Kindly Create your account</Typography>
      </Box>
      <Box sx={{ width: "80%", px: "20px" }}>
        <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
          <InputText
            id="username"
            label="Username"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <InputText
            id="email"
            label="Email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <InputText
            id="fullname"
            label="Full Name (Surname first)"
            value={formik.values.fullname}
            onChange={formik.handleChange}
            error={formik.touched.fullname && Boolean(formik.errors.fullname)}
            helperText={formik.touched.fullname && formik.errors.fullname}
          />
          <InputText
            id="password"
            label="Password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          {isLoading ? (
            <LoadingButton loading variant="outlined" fullWidth>
              Submit
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{ background: theme.palette.primary.dark, my: "10px" }}
              disableElevation
              fullWidth
              type="submit"
            >
              Sign Up
            </Button>
          )}
        </form>
        <Link to="/login" style={{ textDecoration: "none" }}>
          <Typography
            variant="button"
            component="div"
            sx={{ cursor: "pointer" }}
          >
            Already have account?
          </Typography>
        </Link>
      </Box>
    </>
  );
};
