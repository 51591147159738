import { Typography } from "@mui/material";
import useUser from "../lib/useUser";
import UserDash from "../Components/Dashboard/UserDash";

const Dashboard = () => {
  const user = useUser((state) => state.user);

  return (
    <>
      {user.role === "USER" && <UserDash />}
      {user.role === "ADMIN" && <Typography>Admin</Typography>}
    </>
  );
};

export default Dashboard;
