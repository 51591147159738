import {
  AppBar,
  Box,
  Grid,
  List,
  ListItemText,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { animateScroll as scroll, scroller } from "react-scroll";
import DehazeOutlinedIcon from "@mui/icons-material/DehazeOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TopNav } from "../Components/Menus/TopNav";
import { useColors } from "../utils/useColor";
import { Link, useLocation } from "react-router-dom";
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import SnackBar from "../Components/Modals/SnackBar";
interface LandinPageProps {
  pageTitle: String;
  children: JSX.Element;
  notLanding?: boolean;
  verifyPage?: boolean;
}
export const LandingPage: FC<LandinPageProps> = ({
  pageTitle,
  children,
  notLanding,
  verifyPage,
}) => {
  const { primary } = useColors();

  const title = `ZudoVite | ${pageTitle}`;

  const theme = useTheme();

  const teaxt = theme.palette.primary;

  const [isToggle, setIsToggle] = useState(false);

  const ToggleMobileMenu = () => {
    setIsToggle(!isToggle);
  };
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const currentYear = new Date().getFullYear();

  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 15) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const { hash } = useLocation();
  const feature = hash ? hash.replace("#", "") : "";

  useEffect(() => {
    if (feature) {
      scroller.scrollTo(feature, {
        duration: 500,
        spy: true,
        offset: -50,
        delay: 0,
        smooth: true,
      });
      window.history.replaceState(
        null,
        "",
        window.location.pathname + window.location.search
      );
    }
  }, [feature]);

  const { isSubmitted, isSuccess, ismessage } = useSelector(
    (state: any) => state.form
  );

  return (
    <>
      <SnackBar
        isSubmitted={isSubmitted}
        isSuccess={isSuccess}
        ismessage={ismessage}
      />
      <Helmet>
        <title>{title}</title>
        {/* <meta name='description' content='ShoutPond, home of ideas' /> */}
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      {/* AppBar */}
      <Stack
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        {/* Add CSS animation keyframes */}
        <style>
          {`
          @keyframes scaleAnimation {
            from {
              transform: scale(1);
            }
            to {
              transform: scale(1.1);
            }
          }
        `}
        </style>
        {scrolling ? (
          <Stack
            sx={{
              position: "fixed",
              background: primary.main,
              height: "45px",
              width: "45px",
              borderRadius: "50%",
              flexWrap: "wrap",
              alignContent: "center",
              justifyContent: "center",
              mb: "15px",
              color: "#fff",
              zIndex: 99,
              bottom: { xs: "5%", lg: "10%" },
              right: "2%",
              cursor: "pointer",
            }}
            className="icon"
            style={{
              animation: "scaleAnimation 1s infinite alternate", // Apply the animation
            }}
            onClick={scrollToTop}
          >
            <KeyboardArrowUpIcon />
          </Stack>
        ) : null}

        {!verifyPage && (
          <AppBar
            position="fixed"
            sx={{
              width: "100%",
              background: "#fff",
              px: { md: "130px" },
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography
                  variant="h6"
                  component="div"
                  fontWeight="bold"
                  sx={{ color: teaxt.dark }}
                >
                  ZudoVite
                </Typography>
              </Link>
              <Box sx={{ display: { sm: "block", md: "none" } }}>
                <Box onClick={ToggleMobileMenu}>
                  {isToggle ? (
                    <CloseOutlinedIcon
                      color="primary"
                      sx={{ fontWeight: "bold" }}
                    />
                  ) : (
                    <DehazeOutlinedIcon
                      color="primary"
                      sx={{ fontWeight: "bold" }}
                    />
                  )}
                </Box>
                {isToggle ? (
                  <Box
                    sx={{
                      display: { sm: "block", md: "none" },
                      position: "absolute",
                      left: "0%",
                    }}
                  >
                    <Paper
                      sx={{
                        maxWidth: "100%",
                        width: width,
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        alignContent: "center",
                        justifyContent: "center",
                        pb: "30px",
                      }}
                    >
                      <TopNav notLanding={notLanding} />
                    </Paper>
                  </Box>
                ) : (
                  ""
                )}
              </Box>

              {isMobile ? null : <TopNav notLanding={notLanding} />}
            </Toolbar>
          </AppBar>
        )}

        {/* Main Content */}
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {children}
        </Box>

        {/* Footer */}
        {!verifyPage && (
          <Stack sx={{ background: primary.dark, mt: "auto" }}>
            <Box sx={{ m: "20px" }}>
              <Grid
                container
                spacing={0}
                columns={17}
                gap={{ xs: 2, md: 0 }}
                sx={{
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                }}
              >
                <Grid xs={17} md={5}>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                        color: "#fff",
                        letterSpacing: "5px",
                        fontSize: "18px",
                      }}
                    >
                      ZudoVite
                    </Typography>
                    <Typography sx={{ textAlign: "justify", color: "#fff" }}>
                      Experience the freedom of uninterrupted browsing with our
                      cutting-edge mobile internet data plans. Say goodbye to
                      buffering and hello to limitless possibilities. Join us
                      today and unlock a world of connectivity!
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  xs={8}
                  md={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    sx={{
                      width: { lg: "50%", xs: "100%" },
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      // justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Quick Links
                    </Typography>
                    <List sx={{ color: "#fff" }}>
                      <Link
                        to="/faqs"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ListItemText primary="FAQs" />
                      </Link>
                      <Link
                        to="/privacy-policy"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ListItemText primary="Privacy Policy" />
                      </Link>
                      <Link
                        to="/terms-and-conditions"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ListItemText primary="Terms and Conditions" />
                      </Link>
                      <Link
                        to="/refund-policy"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ListItemText primary="Refund Policy" />
                      </Link>
                      <Link
                        to="/contact-us"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <ListItemText primary="Contact Us" />
                      </Link>
                    </List>
                  </Box>
                </Grid>
                <Grid
                  xs={8}
                  md={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    sx={{
                      width: { lg: "50%", xs: "100%" },
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Follow us on
                    </Typography>
                    <Box sx={{ mt: "10px", display: "flex", gap: 2 }}>
                      <BsFacebook
                        style={{
                          fontSize: "24px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                      <BsInstagram
                        style={{
                          fontSize: "24px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                      <BsTwitterX
                        style={{
                          fontSize: "24px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  xs={8}
                  md={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    sx={{
                      width: { lg: "50%", xs: "100%" },
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      // justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Contact Us
                    </Typography>
                    <List sx={{ color: "#fff" }}>
                      <ListItemText primary="+2348111268864" />
                      <ListItemText primary="zudodata@gmail.com" />
                      <ListItemText primary="23B Akinbimi Street, Ondo, Ondo State" />
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                pt: { xs: "40px", lg: "40px" },
                mb: "10px",
              }}
            >
              <Typography
                sx={{ color: "#fff", fontWeight: 500, fontFamily: "Roboto" }}
              >
                Copyright © {currentYear} ZudoVite
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </>
  );
};
