import { create } from "zustand";
import { IUsers } from "../types/common";
import { decryptData, encryptData } from "../utils/Encryptor";

const userData = localStorage.getItem("USER") as any;

export interface IUserData {
  user: IUsers;
  accessToken: string;
}

interface Store {
  user: IUsers;
  updateUser: (user: IUsers) => void;
  updateAuthState: (data?: IUserData) => void;
}

const useUser = create<Store>(
  (set): Store => ({
    user: userData && decryptData(userData),
    updateAuthState: (data?: IUserData) => {
      if (data) {
        localStorage.setItem("TOKEN", encryptData(data.accessToken));
        localStorage.setItem("USER", encryptData(data.user));
      } else {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("USER");
      }
      set((state) => ({
        user: data?.user,
      }));
    },
    updateUser: (user: IUsers) => {
      set((state) => {
        const newData = { ...state.user, ...user };
        localStorage.setItem("USER", encryptData(newData));
        return {
          user: newData,
        };
      });
    },
  })
);

export default useUser;
