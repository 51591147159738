import CryptoJS from "crypto-js";

const key = `${process.env.REACT_APP_ENCRYPT_KEY}`;

// Encrypt function
export const encryptData = (data: any) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    key
  ).toString();
  return encryptedData;
};

// Decrypt function
export const decryptData = (encryptedData: string) => {
  if (!encryptedData) {
    console.error("No encrypted data provided");
    return null;
  }

  try {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key);
    const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);

    if (!decryptedString) {
      console.error("Decryption failed: result is empty");
      return null;
    }

    const decryptedData = JSON.parse(decryptedString);
    return decryptedData;
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};
