/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useColors } from "../utils/useColor";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstances from "../hooks/axiosInstances";
import { useDispatch } from "react-redux";
import { clearMessage, setMessage, setSubmitted, setSuccess } from "../utils/formSlice";

const VerifyAccount = () => {
  const [loading] = React.useState(true);
  const { email, token } = useParams<{ email: string; token: string }>();

  let navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const verification = async (email?: string, token?: string) => {
      await axiosInstances
        .post(`/users/verify/${email}/${token}`)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          dispatch(setSuccess());
          dispatch(setSubmitted());
          setTimeout(() => {
            navigate("/login");
            dispatch(clearMessage());
          }, 2000);
        })
        .catch(function (error) {
          console.log(error);
          dispatch(setMessage(error.response.data.error));
          setTimeout(() => {
            navigate("/");
            dispatch(clearMessage());
          }, 3000);
        });
    };

    verification(email, token);
  }, [email, token]);

  const { primary } = useColors();
  return (
    <Box>
      {/* Overlay with Circular Loading */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress sx={{ color: primary.dark }} />
      </Backdrop>
    </Box>
  );
};

export default VerifyAccount;
