import data from "../assets/Images/data-subscription.png";
import cable from "../assets/Images/cable.png";
import bills from "../assets/Images/Bill.png";
import airtime from "../assets/Images/airtime.png";
import number from "../assets/Images/number.png";
import education from "../assets/Images/educational.png";

export const HomeLinks = [
  {
    text: "Home",
    path: "home",
  },
  {
    text: "Features",
    path: "features",
  },
  {
    text: "Service",
    path: "services",
  },
  {
    text: "Pricing",
    path: "pricing",
  },
];

export const services = [
  {
    service: "Data Subscription",
    image: data,
  },
  {
    service: "Cable Subscription",
    image: cable,
  },
  {
    service: "Virtual Account",
    image: bills,
  },
  {
    service: "Virtual Number",
    image: number,
  },
  {
    service: "Airtime",
    image: airtime,
  },
  {
    service: "Educational",
    image: education,
  },
];
