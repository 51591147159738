// formSlice.js

import { createSlice } from "@reduxjs/toolkit";

interface FormState {
  isLoading: boolean;
  isSubmitted: boolean;
  isError: boolean;
  isSuccess: boolean;
  ismessage: string;
}

const initialState: FormState = {
  isLoading: false,
  isSubmitted: false,
  isError: false,
  isSuccess: false,
  ismessage: "",
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setLoading: (state: any, actions) => {
      state.isLoading = actions.payload;
    },
    setSubmitted: (state: any) => {
      state.isSubmitted = true;
    },
    setError: (state: any) => {
      state.isError = true;
    },
    setSuccess: (state: any) => {
      state.isSuccess = true;
    },
    setMessage: (state: any, actions: any) => {
      state.ismessage = actions.payload;
    },
    clearMessage: (state) => {
      state.ismessage = "";
      state.isSuccess = false;
      state.isSubmitted = false;
    },
  },
});

export const {
  setLoading,
  setSubmitted,
  setError,
  setSuccess,
  setMessage,
  clearMessage,
} = formSlice.actions;
export const selectForm = (state: any) => state.form;
export default formSlice.reducer;
