import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { PasswordReset } from "./PasswordReset";
import { InputText } from "../TextFields/InputText";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  setLoading,
  setSubmitted,
  setSuccess,
  setMessage,
  clearMessage,
} from "../../utils/formSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link, useNavigate } from "react-router-dom";
import useUser from "../../lib/useUser";
import AxiosInstance from "../../hooks/axiosInstances";
import { loginValidation } from "../../utils/validationScheme";

export const Login = () => {
  const [resetpassword, setresetpassword] = useState(false);
  const updateAuthState = useUser((state) => state.updateAuthState);
  const theme = useTheme();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const NavigatePassword = () => {
    setresetpassword(!resetpassword);
  };

  const { isLoading } = useSelector((state: any) => state.form);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginValidation,
    onSubmit: (values) => {
      dispatch(setLoading(true));
      AxiosInstance.post(`/users/login`, values)
        .then((response: any) => {
          dispatch(setMessage(response.data.message));
          dispatch(setSuccess());
          dispatch(setSubmitted());
          dispatch(setLoading(false));

          updateAuthState(response.data);
          // redirect user to the auth page
          setTimeout(() => {
            navigate("/dashboard");
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          dispatch(setMessage(error.response.data.error));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });
  return (
    <>
      {resetpassword ? (
        <PasswordReset NavigatePassword={NavigatePassword} />
      ) : (
        <>
          <Box pb="50px" textAlign="center">
            <Typography variant="h6" fontWeight="bold" component="div">
              Sign In
            </Typography>
            <Typography variant="body1">Kindly login your account</Typography>
          </Box>
          <Box sx={{ width: "80%", px: "20px" }}>
            <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
              <InputText
                id="username"
                label="Username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
              <InputText
                id="password"
                label="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormControlLabel control={<Checkbox />} label="Remember me" />

                <Typography
                  variant="button"
                  textAlign="right"
                  component="a"
                  sx={{ cursor: "pointer" }}
                  onClick={NavigatePassword}
                >
                  Forget Password?
                </Typography>
              </Stack>
              {isLoading ? (
                <LoadingButton loading variant="outlined" fullWidth>
                  Submit
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  sx={{ background: theme.palette.primary.dark, my: "10px" }}
                  disableElevation
                  fullWidth
                  type="submit"
                >
                  Sign In
                </Button>
              )}
            </form>
            <Link to="/signup" style={{ textDecoration: "none" }}>
              <Typography
                variant="button"
                textAlign="right"
                component="div"
                sx={{ cursor: "pointer" }}
              >
                Don't have account?
              </Typography>
            </Link>
          </Box>
        </>
      )}
    </>
  );
};
