import { Box, Button } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { HomeLinks } from "../../utils/HomeLinks";
import { Link as ScrollLink } from "react-scroll";

interface Props {
  notLanding?: boolean;
}

export const TopNav: FC<Props> = ({ notLanding }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row", lg: "row" },
        }}
      >
        {HomeLinks.map((page) =>
          notLanding ? (
            <Link
              to={`/#${page.path}`}
              key={page.text}
              style={{ textDecoration: "none" }}
            >
              <Button sx={{ my: 2, display: "block", fontWeight: "bold" }}>
                {page.text}
              </Button>
            </Link>
          ) : (
            <ScrollLink
              to={page.path}
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              key={page.text}
              style={{ textDecoration: "none" }}
            >
              <Button sx={{ my: 2, display: "block", fontWeight: "bold" }}>
                {page.text}
              </Button>
            </ScrollLink>
          )
        )}
      </Box>
      <Box sx={{ display: "flex" }}>
        <Link to="/login">
          <Button
            variant="outlined"
            sx={{ background: "primary", fontWeight: "bold", mr: "10px" }}
            disableElevation
          >
            Login
          </Button>
        </Link>

        <Link to="/signup">
          <Button
            variant="contained"
            sx={{ background: "primary", fontWeight: "bold" }}
            disableElevation
          >
            Register
          </Button>
        </Link>
      </Box>
    </>
  );
};
