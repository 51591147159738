/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  AppBar,
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import MenuList from "../utils/Links";
import Appbaravatar from "../Components/Avatar/Appbar";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DehazeOutlinedIcon from "@mui/icons-material/DehazeOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import useUser from "../lib/useUser";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import SnackBar from "../Components/Modals/SnackBar";
import { useColors } from "../utils/useColor";
import HomeIcon from "@mui/icons-material/Home";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { removeLeadingSlash } from "../utils/utils";
import axiosInstances from "../hooks/axiosInstances";

interface LayoutProps {
  children: JSX.Element;
  pageTitle: String;
}

const Layout: FC<LayoutProps> = ({ children, pageTitle }) => {
  const user = useUser((state) => state.user);

  const handleFullScreen = () => {
    const element = document.documentElement;

    if (document.fullscreenEnabled) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        element.requestFullscreen();
      }
    }
  };
  const [istoggle, setIsToggle] = useState(false);

  const drawerWidth = 240;

  const title = `ZudoVite | ${pageTitle}`;

  const handleToggle = () => {
    setIsToggle(!istoggle);
  };

  const [userImage, setUserImage] = useState("");

  const fetchAccountTransaction = async () => {
    await axiosInstances.post("/accountnumber");
  };

  useEffect(() => {
    setUserImage(user.avatar);
    fetchAccountTransaction();
  }, []);

  const theme = useTheme();
  const { isSubmitted, isSuccess, ismessage } = useSelector(
    (state: any) => state.form
  );

  const location = useLocation();
  const linkPath = location.pathname.split("/")[1];

  const { primary } = useColors();

  const [openMenuList, setOpenMenuList] = useState(true);
  const [category, setCategory] = useState("");

  const handleClickMenu = async (data: string) => {
    await setCategory(data);
    await setOpenMenuList(!openMenuList);
  };
  const role = user.role;
  const menuItems = MenuList[role as keyof typeof MenuList] || [];
  return (
    <>
      <SnackBar
        isSubmitted={isSubmitted}
        isSuccess={isSuccess}
        ismessage={ismessage}
      />
      <Helmet>
        <title>{title}</title>
        {/* <meta name='description' content='ShoutPond, home of ideas' /> */}
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      {/* App bar */}
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
          ml: `${drawerWidth}px`,
          background: theme.palette.primary.dark,
        }}
        elevation={1}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            flexGrow={1}
            onClick={handleToggle}
          >
            <DehazeOutlinedIcon sx={{ display: { md: "none", xs: "block" } }} />
          </Typography>
          <Tooltip title="FullScreen">
            <FullscreenIcon
              sx={{ cursor: "pointer" }}
              onClick={handleFullScreen}
            />
          </Tooltip>
          <Typography mx={2} fontWeight={"bold"}>
            {user.username}
          </Typography>
          <Appbaravatar userData={userImage} />
        </Toolbar>
      </AppBar>
      {/* Fixed sidebar */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          display: { xs: istoggle ? "block" : "none", md: "block" },
        }}
        variant="permanent"
        anchor="left"
        classes={{ paper: "100%" }}
      >
        <Stack position="relative">
          <Box
            sx={{
              position: "absolute",
              zIndex: 99999,
              right: "0%",
              mt: "20px",
              mr: "10px",
            }}
            onClick={handleToggle}
          >
            <ArrowBackIosOutlinedIcon
              sx={{ display: { md: "none", xs: "block" } }}
            />
          </Box>
          <Box display={"flex"} justifyContent={"center"} p={"30px"}>
            <Typography
              variant="h5"
              noWrap
              component="div"
              fontWeight="bold"
              color="primary"
            >
              ZudoVite
            </Typography>
          </Box>

          <Divider />
          <List>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: linkPath === "dashboard" ? "#fff" : "#000",
              }}
              onClick={handleToggle}
            >
              <ListItem
                key="Dashboard"
                disablePadding
                sx={{
                  display: "block",
                  background: linkPath === "dashboard" ? primary.dark : "",
                  "& .icon": {
                    color: linkPath === "dashboard" ? "#fff" : "",
                  },
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      justifyContent: "center",
                    }}
                  >
                    <HomeIcon color="primary" className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
            </Link>
            {menuItems.map((data: any, index) => (
              <Link
                key={index}
                to={data.category ? "" : data.path}
                style={{
                  textDecoration: "none",
                  color:
                    !data?.category &&
                    linkPath === removeLeadingSlash(data.path)
                      ? "#fff"
                      : "#000",
                }}
                onClick={() =>!data.category && handleToggle()}
              >
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                    background:
                      !data.category &&
                      linkPath === removeLeadingSlash(data.path)
                        ? primary.dark
                        : "",
                    "& .icon": {
                      color:
                        !data.category &&
                        linkPath === removeLeadingSlash(data.path)
                          ? "#fff"
                          : "",
                    },
                  }}
                >
                  <ListItemButton
                    onClick={() =>
                      data.category && handleClickMenu(data.category.text)
                    }
                    sx={{
                      minHeight: 48,
                      justifyContent: "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center",
                      }}
                    >
                      {data.category ? data.category.icon : data.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={`${
                        data.category ? data.category.text : data.text
                      }`}
                    />
                    {data.category &&
                      (category === data.category.text && openMenuList ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      ))}
                  </ListItemButton>
                  {data.category && (
                    <Collapse
                      in={category === data.category.text && openMenuList}
                      timeout="auto"
                      unmountOnExit
                    >
                      {data.category &&
                        data.category.other.map((data: any, index: any) => (
                          <Link
                            key={index}
                            to={data.path}
                            style={{
                              textDecoration: "none",
                              color:
                                linkPath === removeLeadingSlash(data.path)
                                  ? "#fff"
                                  : "#000",
                            }}
                            onClick={() =>handleToggle()}
                          >
                            <List
                              key={index}
                              component="div"
                              disablePadding
                              sx={{
                                display: "block",
                                background:
                                  linkPath === removeLeadingSlash(data.path)
                                    ? primary.dark
                                    : "",
                                "& .icon": {
                                  color:
                                    linkPath === removeLeadingSlash(data.path)
                                      ? "#fff"
                                      : "",
                                },
                              }}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>{data.icon}</ListItemIcon>
                                <ListItemText primary={data.text} />
                              </ListItemButton>
                            </List>
                          </Link>
                        ))}
                    </Collapse>
                  )}
                </ListItem>
              </Link>
            ))}
          </List>
        </Stack>
      </Drawer>
      {/* Content */}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, ml: { md: `${drawerWidth}px` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </>
  );
};

export default Layout;
