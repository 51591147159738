import {
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Person3Icon from "@mui/icons-material/Person3";
import LogoutIcon from "@mui/icons-material/Logout";
import useUser from "../../lib/useUser";
import { FC } from "react";

interface AppbaravatarProps {
  userData: string | null;
}

const Appbaravatar: FC<AppbaravatarProps> = ({ userData }) => {
  const user = useUser((state) => state.user);

  const ProfileAvatar = [
    {
      text: "Profile",
      icon: <Person3Icon color="primary" />,
      path: "/profile",
    },
    {
      text: "Logout",
      icon: <LogoutIcon color="primary" />,
      path: "",
    },
  ];

  const openLink = (url: string | URL | undefined) => {
    window.open(url, "_self");
  };

  const logout = () => {
    // destroy the cookie
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("USER");
    localStorage.removeItem("AVATAR");
    // redirect user to the landing page
    window.location.href = "/";
  };
  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <Avatar
              sx={{ width: 40, height: 40, cursor: "pointer" }}
              src={userData ? userData : ""}
              {...bindTrigger(popupState)}
            >
              {!userData && user.username[0]}
            </Avatar>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              elevation={1}
            >
              <List>
                {ProfileAvatar.map((text, index) => (
                  <ListItem
                    key={text.text}
                    disablePadding
                    // button
                    sx={{ display: "block" }}
                    onClick={() =>
                      text.path === "" ? logout() : openLink(text.path)
                    }
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "center",
                        px: 6,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 5,
                          justifyContent: "center",
                        }}
                      >
                        {text.icon}
                      </ListItemIcon>
                      <ListItemText primary={text.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Popover>
          </div>
        )}
      </PopupState>
    </>
  );
};

export default Appbaravatar;
