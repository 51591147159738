import Grid from "@mui/material/Unstable_Grid2";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import AvatarUploader from "../Components/Avatar/AvatarUploader";
import useUser from "../lib/useUser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputText } from "../Components/TextFields/InputText";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setMessage,
  setSubmitted,
  setSuccess,
} from "../utils/formSlice";
import AxiosInstance from "../hooks/axiosInstances";
import { LoadingButton } from "@mui/lab";
import { IUsers } from "../types/common";
import ChangePassword from "../Components/Profile/ChangePassword";
import { Item } from "../utils/ItemStyle";
import Kyc from "../Components/Kyc";
import ButtonComp from "../Components/Buttons/ButtonComp";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { useColors } from "../utils/useColor";

const Profile = () => {
  const user = useUser((state) => state.user);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: any) => state.form);
  const updateUser = useUser((state) => state.updateUser);
  const { success, error } = useColors();

  const formik = useFormik({
    initialValues: {
      email: user.email,
      username: user.username,
      fullname: user.fullname,
      phone: user.phone,
      contact: user.contact,
    },
    onSubmit: (values) => {
      dispatch(setLoading(true));
      AxiosInstance.put(`/users`, values)
        .then((response) => {
          dispatch(setMessage(response.data.message));
          dispatch(setSuccess());
          dispatch(setSubmitted());
          dispatch(setLoading(false));
          updateUser(response.data.user as IUsers);
        })
        .catch((error) => {
          dispatch(setMessage(error.message));
          dispatch(setLoading(false));
        });
    },
  });

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} columns={16} gap={{ xs: 5, md: 0 }}>
          <Grid xs={16} md={5}>
            <Item
              sx={{
                height: "100%",
                pt: "30px",
                overflow: "hidden",
                textAlign: "center",
                maxHeight: "300px",
              }}
            >
              <AvatarUploader imageUrl={user.avatar ? user.avatar : ""} />
              <Box sx={{ mt: "20px", lineHeight: 3 }}>
                <Typography fontWeight="bold">
                  {user.username.toUpperCase()}
                </Typography>
                <Typography fontWeight="bold">
                  {user.fullname.toUpperCase()}
                </Typography>
              </Box>
            </Item>
            <Item>
              {/* Second Accordion */}
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography fontWeight="bold">KYC Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {user.kyc ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                        color: success.main,
                        alignItems: "center",
                      }}
                    >
                      <VerifiedOutlinedIcon sx={{ fontSize: "28px" }} />
                      <Typography fontWeight="bold">KYC Verified</Typography>
                    </Box>
                  ) : (
                    <Kyc />
                  )}
                </AccordionDetails>
              </Accordion>
            </Item>
          </Grid>
          <Grid md={11} xs={16}>
            <Grid container spacing={2} columns={11}>
              <Grid md={5.5}>
                <Item>
                  <Accordion elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography fontWeight="bold">
                          Identity Verification
                        </Typography>
                        <Typography>Verify your identity.</Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ textAlign: "left" }}>
                      <Typography>
                        To comply with regulation, participant will have to go
                        through Know Your Customer (KYC) verification.
                      </Typography>
                      <Typography>
                        You have to submit your NIN, BVN and Bank Account for
                        verification. This will cost a charge of{" "}
                        <span style={{ fontWeight: "bold" }}>#100.00</span>
                      </Typography>
                      <Typography sx={{ color: error.main }}>
                        * KYC verification required to unlock certain options
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Item>
              </Grid>
              <Grid md={5.5}>
                <Item>
                  <Box sx={{ ml: 2 }}>
                    <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
                      Note
                    </Typography>
                  </Box>
                </Item>
              </Grid>
            </Grid>
            <Item>
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography fontWeight="bold">Personal Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    autoComplete="off"
                  >
                    <InputText
                      id="email"
                      label="Email"
                      type="email"
                      disabled
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <InputText
                      id="username"
                      label="Username"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.username &&
                        Boolean(formik.errors.username)
                      }
                      helperText={
                        formik.touched.username && formik.errors.username
                      }
                    />
                    <InputText
                      id="fullname"
                      label="Full Name"
                      value={formik.values.fullname}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.fullname &&
                        Boolean(formik.errors.fullname)
                      }
                      helperText={
                        formik.touched.fullname && formik.errors.fullname
                      }
                    />
                    <InputText
                      id="phone"
                      label="Phone Number"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                    <InputText
                      id="contact"
                      label="Contact Address"
                      value={formik.values.contact}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.contact && Boolean(formik.errors.contact)
                      }
                      helperText={formik.touched.phone && formik.errors.contact}
                    />
                    {isLoading ? (
                      <LoadingButton loading variant="outlined" fullWidth>
                        Submit
                      </LoadingButton>
                    ) : (
                      <ButtonComp
                        variant="contained"
                        text="Update Profile"
                        type="submit"
                        disableElevation
                        fullWidth
                      />
                    )}
                  </form>
                </AccordionDetails>
              </Accordion>
            </Item>
            <Item>
              <ChangePassword />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Profile;
