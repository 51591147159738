import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";

interface FeatureSectionProps {
  icon: JSX.Element;
  title: string;
  subtitle: string;
}

export const FeatureComp: FC<FeatureSectionProps> = ({
  icon,
  title,
  subtitle,
}) => {
  const theme = useTheme();
  const teaxt = theme.palette.primary;
  return (
    <>
      <Grid
        item
        xs={10} // Full width on mobile
        md={3}
        bgcolor="#fff"
        sx={{
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          py: { md: "10px", xs: "20px" },
          pl: "13px",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)", // Softer shadow
        }}
      >
        <Box
          sx={{
            mr: { xs: "10px", md: "20px" }, // Responsive margin
            gap: 1,
          }}
        >
          <Stack
            sx={{
              background: teaxt.dark,
              height: "45px",
              width: "45px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center", // Improved centering
              justifyContent: "center",
              mb: "15px",
            }}
          >
            {icon}
          </Stack>
          <Typography
            fontWeight="bold"
            sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}
          >
            {title}
          </Typography>
        </Box>

        <Typography
          sx={{
            lineHeight: "1.5",
            textAlign: "justify",
            pr: { xs: "10px", md: "20px" },
          }}
        >
          {subtitle}
        </Typography>
      </Grid>
    </>
  );
};
