import { InputText } from "./TextFields/InputText";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setMessage,
  clearMessage,
} from "../utils/formSlice";
import axiosInstance from "../hooks/axiosInstances";
import ButtonComp from "./Buttons/ButtonComp";
import useUser from "../lib/useUser";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { banks } from "../utils/constants";

const Kyc = () => {
  const user = useUser((state) => state.user);
  const bank = banks;

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: any) => state.form);

  const formik = useFormik({
    initialValues: {
      bvn: user.bvn,
      nin: "",
      bankCode: "",
      accountNumber: "",
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      axiosInstance
        .post(`/users/kyc`, value)
        .then((response) => {
          if (response && response.status === 200) {
            window.location.href = response.data;
          }
        })
        .catch((error) => {
          dispatch(setMessage(error.response.data.error));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
        <InputText
          id="bvn"
          label="BVN"
          // disabled={user.bvn}
          value={formik.values.bvn}
          onChange={formik.handleChange}
          error={formik.touched.bvn && Boolean(formik.errors.bvn)}
          helperText={formik.touched.bvn && formik.errors.bvn}
        />
        <InputText
          id="nin"
          label="NIN"
          // disabled={user.nin}
          value={formik.values.nin}
          onChange={formik.handleChange}
          error={formik.touched.nin && Boolean(formik.errors.nin)}
          helperText={formik.touched.nin && formik.errors.nin}
        />
        <Box sx={{ mb: "15px" }}>
          <FormControl
            fullWidth
            error={formik.touched.bankCode && Boolean(formik.errors.bankCode)}
          >
            <InputLabel id="demo-select-small-label">Bank</InputLabel>
            <Select
              labelId="Bank"
              id="bankCode"
              name="bankCode"
              label="Bank"
              value={formik.values.bankCode}
              onChange={formik.handleChange}
              error={formik.touched.bankCode && Boolean(formik.errors.bankCode)}
              sx={{
                textAlign: "left",
              }}
            >
              {bank &&
                bank.map((data, index) => (
                  <MenuItem key={index} value={data.bankCode}>
                    {data.bankName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {formik.touched.bankCode && formik.errors.bankCode && (
            <Typography
              color="error"
              fontSize={"0.75rem"}
              ml={"14px"}
              sx={{
                textAlign: "left",
              }}
            >
              {formik.errors.bankCode}
            </Typography>
          )}
        </Box>
        <InputText
          id="accountNumber"
          label="Account Number"
          // disabled={user.nin}
          value={formik.values.accountNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.accountNumber && Boolean(formik.errors.accountNumber)
          }
          helperText={
            formik.touched.accountNumber && formik.errors.accountNumber
          }
        />
        {isLoading ? (
          <LoadingButton loading variant="outlined" fullWidth>
            Submit
          </LoadingButton>
        ) : (
          <ButtonComp
            variant="contained"
            text="Submit KYC"
            type="submit"
            disableElevation
            fullWidth
            // disabled={user.bvn}
          />
        )}
      </form>
    </>
  );
};

export default Kyc;
