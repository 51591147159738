import { Box, Stack, Typography, useTheme } from "@mui/material";
import bg from "../assets/Images/login.svg";
import { FC } from "react";
import { Login } from "../Components/Auth/Login";
import { Signup } from "../Components/Auth/Signup";
import HomeIcon from "@mui/icons-material/Home";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import SnackBar from "../Components/Modals/SnackBar";
import { useSelector } from "react-redux";
interface AuthProps {
  islogin?: boolean;
}
const Auth: FC<AuthProps> = ({ islogin }) => {
  const theme = useTheme();

  const { isSubmitted, isSuccess, ismessage } = useSelector((state: any) => state.form);

  return (
    <>
      <SnackBar
        isSubmitted={isSubmitted}
        isSuccess={isSuccess}
        ismessage={ismessage}
      />
      <Helmet>
        <title>
          {islogin ? "ZudoData | Login Page" : "ZudoData | Register Page"}
        </title>
        {/* <meta name='description' content='ShoutPond, home of ideas' /> */}
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        flexWrap={"wrap"}
        sx={{ background: theme.palette.primary.main, height: "100vh" }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            width: { md: "60%", xs: "80%" },
            height: { md: "80vh" },
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
              background: theme.palette.primary.dark,
              display: { md: "block", xs: "none" },
            }}
            width="50%"
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              color="#fff"
              component="div"
              p="20px"
            >
              ZudoData
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              color="#fff"
              component="div"
              p="20px"
            >
              {islogin
                ? "Welcome Back to ZudoData"
                : "Create ZudoData Account with few steps"}
            </Typography>
            <Box p="30px" mt="40px">
              <img src={bg} alt="" width={"80%"} />
            </Box>
          </Box>
          <Box
            bgcolor={"#fff"}
            width={{ md: "50%", xs: "100%" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignContent="center"
            flexWrap="wrap"
            position="relative"
            py={{ md: "0px", xs: "70px" }}
          >
            <Link to="/">
              <Box
                display="flex"
                position="absolute"
                top="10px"
                right="10px"
                flexWrap="wrap"
                sx={{ cursor: "pointer" }}
              >
                {" "}
                <HomeIcon sx={{ color: theme.palette.primary.dark }} />
                <Typography fontWeight={"bold"}>Home</Typography>
              </Box>
            </Link>

            {islogin ? <Login /> : <Signup />}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Auth;
