import { Box,  Typography } from "@mui/material";
import React, { FC } from "react";
import { useColors } from "../../utils/useColor";
import ButtonComp from "../Buttons/ButtonComp";

interface HeaderTextButtonProps {
  headerName: string;
  buttonName?: string;
  otherButtonName?: string;
  otherOnclickfunction?: () => void;
  onclickfunction?: () => void;
  otherIcon?: JSX.Element;
  icon?: JSX.Element;
}
export const HeaderTextButton: FC<HeaderTextButtonProps> = ({
  headerName,
  buttonName,
  otherButtonName,
  otherOnclickfunction,
  otherIcon,
  onclickfunction,
  icon,
}) => {
  const { primary } = useColors();
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>{headerName}</Typography>

        <Box sx={{ display: "flex", gap: 3 }}>
          {otherButtonName && otherOnclickfunction && (
            <ButtonComp
              type="button"
              text={`${otherButtonName} ${otherIcon ? otherIcon : ""}`}
              variant="contained"
              sx={{
                background: primary.main,
                color: "#fff",
                fontWeight: "bold",
              }}
              disableElevation
              onClick={() => otherOnclickfunction()}
            />
          )}
          {buttonName && onclickfunction && (
            <ButtonComp
              type="button"
              text={`${buttonName} ${icon ? icon : ""}`}
              variant="contained"
              sx={{
                background: primary.dark,
                color: "#fff",
                fontWeight: "bold",
              }}
              disableElevation
              onClick={() => onclickfunction()}
            />
          )}
        </Box>
      </Box>
    </div>
  );
};
