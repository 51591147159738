/* eslint-disable react-hooks/exhaustive-deps */
// import { DateRange } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import axiosInstances from "../hooks/axiosInstances";
import { ITransactions } from "../types/common";
import TransactionTable from "../Components/Tables/TransactionTable";
import useUser from "../lib/useUser";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Data from "../Components/Receipt/Data";

const Transaction = () => {
  const user = useUser((state) => state.user);
  const [transactions, setTransactions] = useState<ITransactions[]>([]);
  const [singleTransact, setSingleTransact] = useState<ITransactions>();
  const [openDialog, setOpenDialog] = useState(false);
  const [editId, setEditId] = useState("");
  const contentRef = useRef(null);

  const handleClickOpen = () => {
    if (openDialog) {
      setEditId("");
    }
    setOpenDialog(!openDialog);
  };

  const fetchTransactions = async () => {
    await axiosInstances
      .get("/transactions")
      .then((res) => {
        setTransactions(res.data.transactions);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDownloadAsImage = async () => {
    if (contentRef.current) {
      const canvas = await html2canvas(contentRef.current);
      const imgData = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = imgData;
      link.download = "receipt.png";
      link.click();
    }
  };

  const handleDownloadAsPDF = async () => {
    if (contentRef.current) {
      const canvas = await html2canvas(contentRef.current);
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("receipt.pdf");
    }
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    handleClickOpen();
  };

  useEffect(() => {
    fetchTransactions();

    if (editId !== "") {
      const filterTransaction = transactions.filter(
        (data) => data.id === editId
      );

      setSingleTransact(filterTransaction[0]);
    }
  }, [editId]);

  return (
    <>
      <HeaderTextButton headerName="Transactions" />

      {user.role === "ADMIN" && (
        <Stack
          sx={{
            bgcolor: "#fff",
            mt: "10px",
            py: "10px",
            displa: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box>
            <Button sx={{ fontWeight: "bold" }}>All</Button>
            <Button sx={{ fontWeight: "bold" }}>Deposited</Button>
            <Button sx={{ fontWeight: "bold" }}>Withdrew</Button>
          </Box>
          <Box>
            <Typography>Date and Time</Typography>
          </Box>
        </Stack>
      )}

      <Box mt="40px">
        <TransactionTable data={transactions} handleEdit={handleEdit} />
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleClickOpen}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Download Transaction Receipt
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={"15px"}>
            Overview of user's profile.
          </DialogContentText>

          <Box
            ref={contentRef}
            sx={{
              border: "1px solid #ccc",
              padding: 2,
              borderRadius: "10px",
              width: "400px",
              margin: "auto",
              background: '#fff'
            }}
          >
            {singleTransact &&
              singleTransact?.description === "Data purchase" && (
                <Data data={singleTransact} />
              )}
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 3,
              pt: 3,
              width: "375px",
              margin: "auto",
            }}
          >
            <Button variant="contained" fullWidth onClick={handleDownloadAsPDF}>
              Download PDF
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleDownloadAsImage}
            >
              Download Image
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Transaction;
