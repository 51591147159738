import Person3Icon from "@mui/icons-material/Person3";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SettingsIcon from "@mui/icons-material/Settings";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CellWifiOutlinedIcon from "@mui/icons-material/CellWifiOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import CastForEducationOutlinedIcon from "@mui/icons-material/CastForEducationOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { Payments } from "@mui/icons-material";
import CableOutlinedIcon from "@mui/icons-material/CableOutlined";

const MenuList = {
  USER: [
    {
      text: "Wallet",
      icon: <AccountBalanceWalletIcon color="primary" className="icon" />,
      path: "/wallet",
    },
    {
      category: {
        text: "Bills Payment",
        icon: <Payments color="primary" className="icon" />,
        other: [
          {
            text: "Buy data",
            icon: <CellWifiOutlinedIcon color="primary" className="icon" />,
            path: "/buy-data",
          },
          {
            text: "Buy airtime",
            icon: <PhoneInTalkOutlinedIcon color="primary" className="icon" />,
            path: "/buy-airtime",
          },
          {
            text: "Educations",
            icon: (
              <CastForEducationOutlinedIcon color="primary" className="icon" />
            ),
            path: "/educations",
          },
        ],
      },
    },
    {
      text: "TV Subscription",
      icon: <CableOutlinedIcon color="primary" className="icon" />,
      path: "/tv-subscription",
    },
    {
      text: "Transactions",
      icon: <CompareArrowsIcon color="primary" className="icon" />,
      path: "/transactions",
    },
    {
      text: "Profile",
      icon: <Person3Icon color="primary" className="icon" />,
      path: "/profile",
    },
    // {
    //   text: "Settings",
    //   icon: <SettingsIcon color="primary" className="icon" />,
    //   path: "/settings",
    // },
  ],

  ADMIN: [
    {
      text: "Services",
      icon: <DesignServicesOutlinedIcon color="primary" className="icon" />,
      path: "/services",
    },
    {
      text: "Products",
      icon: <CategoryOutlinedIcon color="primary" className="icon" />,
      path: "/products",
    },
    {
      text: "Price Control",
      icon: <MonetizationOnOutlinedIcon color="primary" className="icon" />,
      path: "/price-control",
    },
    {
      text: "Wallet",
      icon: <AccountBalanceWalletIcon color="primary" className="icon" />,
      path: "/wallet",
    },
    {
      text: "User Wallet",
      icon: <AccountBalanceWalletIcon color="primary" className="icon" />,
      path: "/user-wallet",
    },
    {
      text: "Users",
      icon: <PeopleAltOutlinedIcon color="primary" className="icon" />,
      path: "/users",
    },
    {
      category: {
        text: "Bills Payment",
        icon: <Payments color="primary" className="icon" />,
        other: [
          {
            text: "Buy data",
            icon: <CellWifiOutlinedIcon color="primary" className="icon" />,
            path: "/buy-data",
          },
          {
            text: "Buy airtime",
            icon: <PhoneInTalkOutlinedIcon color="primary" className="icon" />,
            path: "/buy-airtime",
          },
          {
            text: "Educations",
            icon: (
              <CastForEducationOutlinedIcon color="primary" className="icon" />
            ),
            path: "/educations",
          },
        ],
      },
    },
    {
      text: "Transactions",
      icon: <CompareArrowsIcon color="primary" className="icon" />,
      path: "/transactions",
    },
    {
      text: "Settings",
      icon: <SettingsIcon color="primary" className="icon" />,
      path: "/settings",
    },
  ],
};

export default MenuList;
