import { Button } from "@mui/material";
import React, { FC } from "react";
import { useColors } from "../../utils/useColor";

interface ButtonCompProps {
  variant: "text" | "contained" | "outlined";
  text: string | undefined;
  type: "button" | "reset" | "submit";
  icon?: JSX.Element;
  background?: string;
  backgroundHover?: string;
  color?: string;
  bordercolor?: string;
  [x: string]: any;
}

const ButtonComp: FC<ButtonCompProps> = ({
  variant,
  text,
  type,
  icon,
  background,
  backgroundHover,
  color,
  BorderColor,
  ...props
}) => {
  const { primary } = useColors();
  return (
    <>
      <Button
        variant={variant}
        type={type}
        sx={{
          background: background
            ? background
            : variant === "contained"
            ? primary.dark
            : "",
          color: color,
          fontWeight: "bold",
          borderColor: BorderColor,
          "&:hover": {
            background: backgroundHover
              ? backgroundHover
              : variant === "contained"
              ? primary.dark
              : "",
          },
        }}
        {...props}
      >
        {text} {icon}
      </Button>
    </>
  );
};

export default ButtonComp;
