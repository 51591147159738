import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Layout from "../Layouts/Layout";
import Wallet from "../pages/Wallet";
import ErrorPage from "../pages/404";
import Auth from "../pages/Auth";
import { LandingPage } from "../pages/LandingPage";
import ProtectedRoutes from "./ProtectedRoutes";
import AuthRoutes from "./AuthRoutes";
import Order from "../pages/order";
import Transaction from "../pages/Transaction";
import Profile from "../pages/Profile";
// import LoadingPage from "../pages/LoadingPage";
import Data from "../pages/data";
import PaymentVerify from "../pages/PaymentVerify";
import RechargeAirtime from "../pages/RechargeAirtime";
import Privacy from "../pages/Privacy";
import Home from "../pages/Home";
import Termsandcondition from "../pages/Termsandcondition";
import RefundPolicy from "../pages/RefundPolicy";
import Contactus from "../pages/Contactus";
import Education from "../pages/Education";
import VerifyAccount from "../pages/VerifyAccount";
import Users from "../pages/Users";
import Services from "../pages/Services";
import Products from "../pages/Products";
import WalletUser from "../pages/WalletUser";
import PriceControl from "../pages/PriceControl";
import KycPage from "../pages/KycPage";
import TvSubscription from "../pages/TvSubscription";

const RoutesComponenet = () => {
  return (
    <Routes>
      <Route element={<AuthRoutes />}>
        <Route
          path="/"
          element={
            <LandingPage pageTitle="ZudoVite">
              <Home />
            </LandingPage>
          }
        />
        <Route path="/login" element={<Auth islogin />} />
        <Route path="/signup" element={<Auth />} />
        <Route
          path="/privacy-policy"
          element={
            <LandingPage pageTitle="Privacy Policy" notLanding>
              <Privacy />
            </LandingPage>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <LandingPage pageTitle="Terms and Conditions" notLanding>
              <Termsandcondition />
            </LandingPage>
          }
        />
        <Route
          path="/refund-policy"
          element={
            <LandingPage pageTitle="Refund Policy" notLanding>
              <RefundPolicy />
            </LandingPage>
          }
        />
        <Route
          path="/contact-us"
          element={
            <LandingPage pageTitle="Contact Us" notLanding>
              <Contactus />
            </LandingPage>
          }
        />
        <Route
          path="/verify/:email/:token"
          element={
            <LandingPage pageTitle="Account Verification" verifyPage>
              <VerifyAccount />
            </LandingPage>
          }
        />
        <Route
          path="*"
          element={
            <LandingPage pageTitle="Page not Found" notLanding>
              <ErrorPage auth />
            </LandingPage>
          }
        />
      </Route>

      {/* Protected Routes */}
      <Route element={<ProtectedRoutes />}>
        <Route
          path="/dashboard"
          element={
            <Layout pageTitle="Zudo Company">
              <Dashboard />
            </Layout>
          }
        />
        <Route
          path="/deposit/verification"
          element={
            <Layout pageTitle="Payment Verification">
              <PaymentVerify />
            </Layout>
          }
        />
        <Route
          path="/kyc"
          element={
            <Layout pageTitle="KYC Verification">
              <KycPage />
            </Layout>
          }
        />

        <Route
          path="/wallet"
          element={
            <Layout pageTitle="Wallet">
              <Wallet />
            </Layout>
          }
        />
        <Route
          path="/tv-subscription"
          element={
            <Layout pageTitle="TV/Cable Subscription">
              <TvSubscription />
            </Layout>
          }
        />
        <Route
          path="/user-wallet"
          element={
            <Layout pageTitle="Users Wallet">
              <WalletUser />
            </Layout>
          }
        />
        <Route
          path="/services"
          element={
            <Layout pageTitle="Services">
              <Services />
            </Layout>
          }
        />
        <Route
          path="/products"
          element={
            <Layout pageTitle="Products">
              <Products />
            </Layout>
          }
        />
        <Route
          path="/price-control"
          element={
            <Layout pageTitle="Price Control">
              <PriceControl />
            </Layout>
          }
        />
        <Route
          path="/users"
          element={
            <Layout pageTitle="Users">
              <Users />
            </Layout>
          }
        />
        <Route
          path="/buy-data"
          element={
            <Layout pageTitle="Buy Data">
              <Data />
            </Layout>
          }
        />
        <Route
          path="/buy-airtime"
          element={
            <Layout pageTitle="Buy Airtime">
              <RechargeAirtime />
            </Layout>
          }
        />
        <Route
          path="/educations"
          element={
            <Layout pageTitle="Buy Educational Scratch Cards">
              <Education />
            </Layout>
          }
        />
        <Route
          path="/order"
          element={
            <Layout pageTitle="Orders Page">
              <Order />
            </Layout>
          }
        />
        <Route
          path="/transactions"
          element={
            <Layout pageTitle="Transactions Page">
              <Transaction />
            </Layout>
          }
        />
        <Route
          path="/profile"
          element={
            <Layout pageTitle="Account">
              <Profile />
            </Layout>
          }
        />
        <Route
          path="*"
          element={
            <Layout pageTitle="Page not Found">
              <ErrorPage />
            </Layout>
          }
        />
      </Route>
    </Routes>
  );
};

export default RoutesComponenet;
