import { Box, Toolbar, Stack, Typography, Grid } from "@mui/material";
import React from "react";
import { FeatureSection } from "../Components/FeatureSection";
import { HeroSection } from "../Components/HeroSection";
import { services } from "../utils/HomeLinks";
import { useColors } from "../utils/useColor";

const Home = () => {
  const { primary } = useColors();
  return (
    <>
      {/* Hero Section */}
      <Box component="main" sx={{ flexGrow: 1 }} id="home">
        <Toolbar />
        <HeroSection />
      </Box>
      {/* Hero Section */}

      {/* Features Section */}
      <FeatureSection />
      {/* Features Section */}

      <Stack sx={{ background: primary.dark }} id="services">
        <Typography
          variant="h5"
          component="div"
          fontWeight="bold"
          textAlign="center"
          sx={{
            my: "20px",
            color: "white",
          }}
        >
          Our Services
        </Typography>

        <Box
          sx={{
            width: { lg: "80%", xs: "100%" },
            maxWidth: "1200px",
            margin: "auto",
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            gap={3}
            justifyContent="center"
            py={5}
          >
            {services.map((data, index) => (
              <Grid
                item
                xs={10}
                sm={6}
                md={3}
                key={index}
                sx={{
                  background: "#fff",
                  minHeight: { lg: "350px", xs: "300px" },
                  maxHeight: { lg: "350px", xs: "300px" },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "10px",
                  overflow: "hidden",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "70%",
                  }}
                >
                  <img src={data.image} alt="" style={{ width: "80%" }} />
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "bold",
                    mt: {lg: "30px", xs: '50px'},
                  }}
                >
                  {data.service}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>

      <Stack sx={{ minHeight: "250px" }}></Stack>
    </>
  );
};

export default Home;
