import React from "react";
import { useColors } from "../utils/useColor";
import { useStyles } from "../utils/useStyles";
import { Container, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Termsandcondition = () => {
  const { primary } = useColors();
  const style = useStyles();
  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          mt: "85px",
        }}
      >
        <Box>
          <Typography
            variant="h2"
            fontWeight={900}
            sx={{
              color: primary.dark,
              fontFamily: "Roboto",
              fontSize: { xs: "2rem", lg: "1.8rem" },
              mb: "30px",
            }}
          >
            Terms and Conditions
          </Typography>
        </Box>
        <Box>
          {/* <Typography sx={style.texts}>
            Effective Date: 1st July, 2024
          </Typography> */}
          {/* First */}
          <Typography sx={style.privacyText}>Introduction</Typography>
          <Typography sx={style.texts}>
            Welcome to ZudoData. These Terms and Conditions govern your use of
            our website and services, including purchasing mobile internet data
            subscriptions, paying bills, buying virtual numbers, and using
            virtual account numbers. By accessing or using ZudoData, you agree
            to be bound by these Terms and Conditions.
          </Typography>
          {/* Second */}
          <Typography sx={style.privacyText}>Definitions</Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>"We," "Us," "Our": Refers to ZudoData.</li>
              <li>
                "You," "Your": Refers to the user or customer of ZudoData.
              </li>
              <li>
                "Services": Refers to the products and services offered by
                ZudoData, including data subscriptions, bill payments, virtual
                numbers, virtual account numbers and more.
              </li>
            </ul>
          </Typography>
          {/* Third */}
          <Typography sx={style.privacyText}>Use of Services</Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                Eligibility: You must be at least 18 years old and capable of
                entering into a legally binding agreement to use our services.
                By using our services, you confirm that you meet this
                requirement.
              </li>
              <li>
                Account Registration: To access certain services, you may be
                required to create an account with accurate and complete
                information. You are responsible for maintaining the
                confidentiality of your account credentials and for all
                activities that occur under your account.
              </li>
              <li>
                Prohibited Activities: You agree not to use ZudoData for any
                unlawful or unauthorized purposes, including but not limited to
                violating any local, state, national, or international laws,
                infringing on the rights of others, or distributing harmful
                content.
              </li>
            </ul>
          </Typography>
          {/* Fourth */}
          <Typography sx={style.privacyText}>Payment and Billing</Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                Pricing: The prices for our services are listed on our website
                and are subject to change without notice. We will notify you of
                any price changes before you make a purchase.
              </li>
              <li>
                Payment Methods: We accept various payment methods as specified
                on our website. You agree to provide accurate payment
                information and authorize us to charge your selected payment
                method for the total amount due.
              </li>
              <li>
                Billing Disputes: If you believe there is an error in your
                billing, please contact us immediately. We will investigate and
                resolve any disputes as quickly as possible.
              </li>
            </ul>
          </Typography>

          {/* Fifth */}
          <Typography sx={style.privacyText}>
            Refunds and Cancellations
          </Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                Refund Policy: Refunds are subject to our refund policy, which
                may vary depending on the service purchased. Please review the
                specific refund terms applicable to your purchase before
                completing the transaction.
              </li>
              <li>
                Cancellations: You may cancel your order at any time before the
                service is rendered. Once a service has been rendered,
                cancellations may not be possible, and you may not be eligible
                for a refund.
              </li>
            </ul>
          </Typography>
          {/* Sixth */}
          <Typography sx={style.privacyText}>
            Limitation of Liability
          </Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                No Warranty: ZudoData is provided "as is" and "as available,"
                without any warranties of any kind, either express or implied.
                We do not guarantee the accuracy, completeness, or reliability
                of the services provided.
              </li>
              <li>
                Limitation of Liability: To the fullest extent permitted by law,
                ZudoData shall not be liable for any indirect, incidental,
                special, consequential, or punitive damages, including but not
                limited to loss of profits, data, or goodwill, arising out of or
                related to your use of our services.
              </li>
            </ul>
          </Typography>
          {/* Seventh */}
          <Typography sx={style.privacyText}>Indemnification</Typography>
          <Typography sx={style.texts}>
            You agree to indemnify, defend, and hold harmless ZudoData, its
            affiliates, and their respective officers, directors, employees, and
            agents from any claims, damages, liabilities, costs, and expenses
            (including reasonable attorneys' fees) arising out of or related to
            your use of our services or violation of these Terms and Conditions.
          </Typography>
          {/* Eighth */}
          <Typography sx={style.privacyText}>Termination</Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                Termination by You: You may terminate your account at any time
                by contacting us or by discontinuing use of our services.
              </li>
              <li>
                Termination by Us: We reserve the right to suspend or terminate
                your account or access to our services at any time, with or
                without cause, and without prior notice if found compromised or
                violated any rule on this platform.
              </li>
            </ul>
          </Typography>
          {/* Nineth */}
          <Typography sx={style.privacyText}>Governing Law</Typography>
          <Typography sx={style.texts}>
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of Federal Republic of Nigeria, without
            regard to its conflict of law principles.
          </Typography>
          <Typography sx={style.privacyText}>
            Changes to Terms and Conditions
          </Typography>
          <Typography sx={style.texts}>
            We may update these Terms and Conditions from time to time. Any
            changes will be posted on this page, and the effective date will be
            updated accordingly. Your continued use of our services after any
            such changes constitutes your acceptance of the new Terms and
            Conditions.
          </Typography>
          {/* Nineth */}
          <Typography sx={style.privacyText}>Contact Us</Typography>
          <Typography sx={style.texts}>
            If you have any questions or concerns about this Privacy Policy,
            please contact us <Link to="/contact-us">here</Link>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default Termsandcondition;
