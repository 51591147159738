// ProtectedRoute.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useUser from "../lib/useUser";

const AuthRoutes = () => {
  const user = useUser((state) => state.user);

  return user ? <Navigate to="/dashboard" replace /> : <Outlet />;
};

export default AuthRoutes;
