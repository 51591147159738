import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";

interface InputTextProps {
  id: string | undefined;
  label: string;
  type?: string;
  onChange?: (e: any) => void;
  [x: string]: any;
}

export const InputText: FC<InputTextProps> = ({
  id,
  label,
  type,
  onChange,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      {type === "password" ? (
        <FormControl sx={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            autoComplete="off"
            id={id}
            label={label}
            type={showPassword ? "text" : type}
            fullWidth
            onChange={onChange}
            {...props}
            sx={{
              "& input": {
                padding: "14px",
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {props.error && (
            <FormHelperText error>{props.helperText}</FormHelperText>
          )}
        </FormControl>
      ) : (
        <TextField
          autoComplete="off"
          id={id}
          label={label}
          type={type}
          fullWidth
          onChange={onChange}
          {...props}
          sx={{
            "& input": {
              padding: "14px", // Adjust the padding to change the height
            },
            mb: "10px",
          }}
        />
      )}
    </>
  );
};
