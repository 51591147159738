/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Stack,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import ButtonComp from "../Components/Buttons/ButtonComp";
import { InputText } from "../Components/TextFields/InputText";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import axiosInstances from "../hooks/axiosInstances";
import { IProductPrice, IProducts, IServices } from "../types/common";
import {
  clearMessage,
  setLoading,
  setMessage,
  setSubmitted,
  setSuccess,
} from "../utils/formSlice";
import DataTable from "../Components/Tables/DataTable";
import ActionModal from "../Components/Modals/ActionModal";

const PriceControl = () => {
  const { isLoading } = useSelector((state: any) => state.form);
  const [openDialog, setOpenDialog] = useState(false);
  const [editId, setEditId] = useState("");
  const [isCategory, setCategory] = useState(false);
  const [services, setServices] = useState<IServices[]>([]);
  const [products, setProducts] = useState<IProducts[]>([]);
  const [filteredroducts, setFilteredProducts] = useState<IProducts[]>([]);
  const [productPrice, setProductPrice] = useState<IProductPrice[]>([]);
  const [open, setOpen] = useState(false);
  const [actionMessage, setActionMessage] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    if (openDialog) {
      setEditId("");
      setCategory(false);
      formik.resetForm();
    }
    setOpenDialog(!openDialog);
  };

  const handleClose = () => {
    setEditId("");
    setDeleteId("");
    setOpen(false);
    formik.resetForm();
  };

  const fetchServices = async () => {
    await axiosInstances
      .get("services")
      .then((res) => {
        setServices(res.data.services);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterService = services.filter((data) => data.visibility === true);

  const fetchProducts = async () => {
    await axiosInstances
      .get("products")
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchAllPrice = async () => {
    await axiosInstances
      .get("/prices/all")
      .then((res) => {
        setProductPrice(res.data.price);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    handleClickOpen();
    fetchProductPrice(id);
  };

  const setMultipleValues = (values: { [key: string]: any }) => {
    Object.keys(values).forEach((key) => {
      formik.setFieldValue(key, values[key]);
    });
  };

  const fetchProductPrice = (id: string) => {
    const filterPrice = productPrice.filter(
      (data) => id === data._id.toString()
    );
    const data = filterPrice[0] as IProductPrice;

    setMultipleValues({
      product: data.product,
      service: data.service,
      category: data.category,
      volume: data.volume,
      price: data.price,
      variant: data.variant,
      days: data.days,
    });
  };

  const handleDelete = (id: string) => {
    setActionMessage("Are you sure you want to delete this price data?");
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = async () => {
    dispatch(setLoading(true));
    await axiosInstances
      .delete(`/prices/${deleteId}`)
      .then((res) => {
        dispatch(setMessage(res.data.message));
        fetchAllPrice();
        dispatch(setSuccess());
        dispatch(setSubmitted());
        dispatch(setLoading(false));
        handleClose();
        setTimeout(() => {
          dispatch(clearMessage());
        }, 3000);
      })
      .catch((error) => {
        dispatch(setMessage(error.response.data.error));
        dispatch(setLoading(false));
        setTimeout(() => {
          dispatch(clearMessage());
        }, 3000);
      });
  };

  const formik = useFormik({
    initialValues: {
      service: "",
      category: "",
      product: "",
      volume: "",
      price: "",
      variant: "",
      days: "",
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      if (editId) {
        axiosInstances
          .put(`/prices/${editId}`, value)
          .then((res) => {
            dispatch(setMessage(res.data.message));
            dispatch(setSuccess());
            dispatch(setSubmitted());
            dispatch(setLoading(false));
            fetchAllPrice();
            // formik.resetForm();
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          })
          .catch((error) => {
            dispatch(setMessage(error.response.data.error));
            dispatch(setLoading(false));
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          });
      } else {
        axiosInstances
          .post("prices", value)
          .then((res) => {
            dispatch(setMessage(res.data.message));
            dispatch(setSuccess());
            dispatch(setSubmitted());
            dispatch(setLoading(false));
            fetchAllPrice();
            // formik.resetForm();
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          })
          .catch((error) => {
            dispatch(setMessage(error.response.data.error));
            dispatch(setLoading(false));
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          });
      }
    },
  });

  useEffect(() => {
    fetchServices();
    fetchProducts();
    fetchAllPrice();

    if (formik.values.service) {
      const filterData = services.filter(
        (data) => data._id === formik.values.service
      );
      if (filterData && filterData[0].title === "Data") {
        setCategory(true);
        // formik.values.product = "";
      } else {
        setCategory(false);
        if (filterData && filterData[0].title === "Scratch Cards") {
          setFilteredProducts([]);
          formik.values.category = "";
          const filterProduct = products.filter(
            (data) =>
              data.service === formik.values.service && data.visibility === true
          );

          setFilteredProducts(filterProduct);
        }
      }
    }

    if (formik.values.category) {
      const filterProduct = products.filter(
        (data) =>
          data.category === formik.values.category && data.visibility === true
      );

      setFilteredProducts(filterProduct);
    }
  }, [formik.values.service, formik.values.category]);
  return (
    <>
      <HeaderTextButton
        headerName="Price Control"
        buttonName="Add New"
        onclickfunction={handleClickOpen}
      />

      <ActionModal
        open={open}
        handleClose={handleClose}
        status="error"
        message={actionMessage}
        confirmAction={() => confirmDelete()}
      />

      <Box mt="40px">
        <DataTable
          data={productPrice}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </Box>

      <Dialog open={openDialog} onClose={handleClickOpen}>
        <DialogTitle sx={{ fontWeight: "bold" }}>
          {editId ? "Update Product Price" : "New Product Price"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={"15px"}>
            Enter a concise yet descriptive product details to create record.
          </DialogContentText>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Stack sx={{ gap: 2 }}>
              <Box>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.service && Boolean(formik.errors.service)
                  }
                >
                  <InputLabel id="demo-select-small-label">Service</InputLabel>
                  <Select
                    labelId="Service"
                    id="service"
                    name="service"
                    label="Service"
                    value={formik.values.service}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.service && Boolean(formik.errors.service)
                    }
                    sx={{ textAlign: "left" }}
                  >
                    {filterService.map((data, index) => (
                      <MenuItem key={index} value={data._id}>
                        {data.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.touched.service && formik.errors.service && (
                  <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                    {formik.errors.service}
                  </Typography>
                )}
              </Box>
              {isCategory && (
                <Box>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                  >
                    <InputLabel id="demo-select-small-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="category"
                      id="category"
                      name="category"
                      label="category"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      sx={{ textAlign: "left" }}
                    >
                      <MenuItem value="MTN">MTN</MenuItem>
                      <MenuItem value="GLO">GLO</MenuItem>
                      <MenuItem value="AIRTEL">AIRTEL</MenuItem>
                      <MenuItem value="9MOBILE">9MOBILE</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.category && formik.errors.category && (
                    <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                      {formik.errors.category}
                    </Typography>
                  )}
                </Box>
              )}
              <Box>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.product && Boolean(formik.errors.product)
                  }
                >
                  <InputLabel id="demo-select-small-label">Product</InputLabel>
                  <Select
                    labelId="Product"
                    id="product"
                    name="product"
                    label="Product"
                    value={formik.values.product}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.product && Boolean(formik.errors.product)
                    }
                    sx={{ textAlign: "left" }}
                  >
                    {filteredroducts.map((data, index) => (
                      <MenuItem key={index} value={data._id.toString()}>
                        {data.product}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.touched.product && formik.errors.product && (
                  <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                    {formik.errors.product}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  gap: 2,
                }}
              >
                {isCategory && (
                  <InputText
                    id="volume"
                    label="Volume"
                    value={formik.values.volume}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.volume && Boolean(formik.errors.volume)
                    }
                    helperText={formik.touched.volume && formik.errors.volume}
                  />
                )}
                <InputText
                  id="price"
                  label="Price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  gap: 2,
                }}
              >
                <InputText
                  id="variant"
                  label="Variant Code"
                  value={formik.values.variant}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.variant && Boolean(formik.errors.variant)
                  }
                  helperText={formik.touched.variant && formik.errors.variant}
                />
                {isCategory && (
                  <InputText
                    id="days"
                    label="Days"
                    value={formik.values.days}
                    onChange={formik.handleChange}
                    error={formik.touched.days && Boolean(formik.errors.days)}
                    helperText={formik.touched.days && formik.errors.days}
                  />
                )}
              </Box>
              {/*
               */}
              <Box>
                {isLoading ? (
                  <LoadingButton loading variant="outlined" fullWidth>
                    Submit
                  </LoadingButton>
                ) : (
                  <ButtonComp
                    text={editId ? "Update" : "Submit"}
                    type="submit"
                    variant="contained"
                    fullWidth
                    disableElevation
                  />
                )}
              </Box>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PriceControl;
