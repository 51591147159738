import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useColors } from "../utils/useColor";
import { useStyles } from "../utils/useStyles";
import { Link } from "react-router-dom";

const Privacy = () => {
  const { primary } = useColors();
  const style = useStyles();
  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          mt: "85px",
          //   background: "red",
        }}
      >
        <Box>
          <Typography
            variant="h2"
            fontWeight={900}
            sx={{
              color: primary.dark,
              fontFamily: "Roboto",
              fontSize: { xs: "2rem", lg: "1.8rem" },
              mb: "30px",
            }}
          >
           Privacy Policy
          </Typography>
        </Box>
        <Box>
          {/* <Typography sx={style.texts}>
            Effective Date: 1st July, 2024
          </Typography> */}
          {/* First */}
          <Typography sx={style.privacyText}>Introduction</Typography>
          <Typography sx={style.texts}>
            Welcome to ZudoData. We value your privacy and are committed to
            protecting your personal information. This Privacy Policy outlines
            how we collect, use, and safeguard your data when you use our
            services, including purchasing mobile internet data subscriptions,
            paying bills, buying virtual numbers, using virtual account
            numbers and more.
          </Typography>
          {/* Second */}
          <Typography sx={style.privacyText}>Information We Collect</Typography>
          <Typography sx={style.textSub}>
            We may collect the following types of information:
          </Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                Personal Information: When you register on ZudoData, we may
                collect personal details such as your name, email address, phone
                number, and payment information.
              </li>
              <li>
                Transaction Information: We collect data on the transactions you
                perform on our platform, including the purchase of data
                subscriptions, bill payments, and virtual number transactions.
              </li>
              <li>
                Usage Data: We collect information on how you interact with our
                website, such as your IP address, browser type, pages visited,
                and the time spent on our site.
              </li>
            </ul>
          </Typography>
          {/* Third */}
          <Typography sx={style.privacyText}>
            How We Use Your Information
          </Typography>
          <Typography sx={style.textSub}>
            We use the information we collect for various purposes, including:
          </Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                To Provide Services: We use your personal and transaction
                information to process your orders, manage your account, and
                provide you with our services.
              </li>
              <li>
                To Improve Our Services: Usage data helps us understand how
                users interact with ZudoData, enabling us to enhance our
                website's functionality and user experience.
              </li>
              <li>
                To Communicate With You: We may send you notifications, updates,
                and promotional materials related to our services. You can opt
                out of marketing communications at any time.
              </li>
              <li>
                For Security Purposes: We use your information to protect our
                platform and users from fraud, unauthorized access, and other
                security threats.
              </li>
            </ul>
          </Typography>
          {/* Fourth */}
          <Typography sx={style.privacyText}>Data Security</Typography>
          <Typography sx={style.texts}>
            We implement industry-standard security measures to protect your
            data from unauthorized access, disclosure, or destruction. However,
            no online platform can guarantee absolute security, and we encourage
            you to take steps to safeguard your information.
          </Typography>

          {/* Fifth */}
          <Typography sx={style.privacyText}>
            Sharing Your Information
          </Typography>
          <Typography sx={style.textSub}>
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties except for:
          </Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                Service Providers: We may share your information with
                third-party service providers who assist us in delivering our
                services (e.g., payment processors, customer support).
              </li>
              <li>
                Legal Compliance: We may disclose your information if required
                by law, regulation, or legal process.
              </li>
              <li>
                Business Transfers: In the event of a merger, acquisition, or
                sale of ZudoData, your information may be transferred to the new
                entity.
              </li>
            </ul>
          </Typography>
          {/* Sixth */}
          <Typography sx={style.privacyText}>Your Rights</Typography>
          <Typography sx={style.textSub}>You have the right to:</Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                Access and Correction: You have the right to access, update, or
                correct your personal information at any time by logging into
                your account.
              </li>
              <li>
                Data Deletion: You may request the deletion of your personal
                information by contacting our support team, subject to legal
                obligations.
              </li>
              <li>
                Opt-Out: You can opt out of receiving marketing communications
                from us by following the unsubscribe instructions in our emails.
              </li>
            </ul>
          </Typography>
          {/* Seventh */}
          <Typography sx={style.privacyText}>Cookies</Typography>
          <Typography sx={style.texts}>
            We use cookies to enhance your browsing experience. Cookies are
            small files stored on your device that help us understand how you
            use our site and improve your experience. You can control your
            cookie preferences through your browser settings.
          </Typography>
          {/* Eighth */}
          <Typography sx={style.privacyText}>Third-Party Links</Typography>
          <Typography sx={style.texts}>
            Our website may contain links to third-party sites. We are not
            responsible for the privacy practices of these sites. We encourage
            you to read their privacy policies.
          </Typography>
          {/* Nineth */}
          <Typography sx={style.privacyText}>
            Changes to This Privacy Policy
          </Typography>
          <Typography sx={style.texts}>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by updating the effective date at the top of this
            policy. Your continued use of our services after any changes
            indicates your acceptance of the updated policy.
          </Typography>
          {/* Nineth */}
          <Typography sx={style.privacyText}>Contact Us</Typography>
          <Typography sx={style.texts}>
            If you have any questions or concerns about this Privacy Policy,
            please contact us <Link to="/contact-us">here</Link>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default Privacy;
