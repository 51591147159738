import { Box, Dialog, DialogContent, DialogContentText } from "@mui/material";
import React, { useState } from "react";
import ButtonComp from "../Buttons/ButtonComp";
import { useColors } from "../../utils/useColor";
import { InputText } from "../TextFields/InputText";
import { useFormik } from "formik";
import { paymentAmount } from "../../utils/validationScheme";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, setLoading, setMessage } from "../../utils/formSlice";
import axiosInstances from "../../hooks/axiosInstances";
import { LoadingButton } from "@mui/lab";

const DepositWithDraw = () => {
  const { primary } = useColors();
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(!openDialog);
  };

  const { isLoading } = useSelector((state: any) => state.form);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: paymentAmount,
    onSubmit: (value) => {
      dispatch(setLoading(true));
      axiosInstances
        .post("/users/deposit", value)
        .then((res) => {
          if (res && res.status === 200) {
            window.location.href = res.data;
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(setMessage(error.response.data.error));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });
  return (
    <>
      <Box sx={{ mb: 3, gap: 2, display: "flex" }}>
        <ButtonComp
          variant="contained"
          type="submit"
          text="Deposit Fund"
          sx={{ px: 4, background: primary.dark }}
          onClick={handleClickOpen}
          // disabled
        />
        <ButtonComp
          variant="outlined"
          type="submit"
          text="Withdraw Fund"
          sx={{ px: 4 }}
          disabled
        />
      </Box>

      <Dialog open={openDialog} onClose={handleClickOpen} fullWidth={true}>
        {/* <DialogTitle sx={{ fontWeight: "bold" }}>Amount</DialogTitle> */}
        <DialogContent>
          <DialogContentText mb={"15px"}>
            Enter amount to deposit. (Fee charge 2%)
          </DialogContentText>
          <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
            <InputText
              id="amount"
              label="Amount"
              type="number"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
            {isLoading ? (
              <LoadingButton loading variant="outlined" fullWidth>
                Submit
              </LoadingButton>
            ) : (
              <ButtonComp
                variant="contained"
                type="submit"
                text="Proceed"
                fullWidth
                disabled={!formik.values.amount}
              />
            )}
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DepositWithDraw;
