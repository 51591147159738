import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonComp from "../Components/Buttons/ButtonComp";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import { InputText } from "../Components/TextFields/InputText";
import { Item } from "../utils/ItemStyle";
import mtnLogo from "../assets/Images/mtn.png";
import gloLogo from "../assets/Images/glo.png";
import airtelLogo from "../assets/Images/airtel.png";
import mobileLogo from "../assets/Images/9mobile.png";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import axiosInstances from "../hooks/axiosInstances";
import { ITransactions } from "../types/common";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../utils/formSlice";
import { TransactionList } from "../Components/others/TransactionList";

const RechargeAirtime = () => {
  const [mtn, setMtn] = useState(false);
  const [glo, setGlo] = useState(false);
  const [airtel, setAirtel] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [transactions, setTransactions] = useState<ITransactions[]>([]);
  const { isLoading } = useSelector((state: any) => state.form);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      serviceID: "",
      phone: "",
      amount: "",
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      axiosInstances
        .post("users/airtime", value)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          dispatch(setSuccess());
          dispatch(setSubmitted());
          dispatch(setLoading(false));
          fetchTransactions();
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          dispatch(setMessage(error.response.data.error));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  const fetchTransactions = async () => {
    await axiosInstances
      .get("/transactions")
      .then((res) => {
        setTransactions(res.data.transactions);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (formik.values.serviceID === "mtn") {
      setMtn(true);
      setGlo(false);
      setAirtel(false);
      setMobile(false);
    }
    if (formik.values.serviceID === "glo") {
      setGlo(true);
      setMobile(false);
      setMtn(false);
      setAirtel(false);
    }
    if (formik.values.serviceID === "airtel") {
      setAirtel(true);
      setGlo(false);
      setMobile(false);
      setMtn(false);
    }
    if (formik.values.serviceID === "etisalat") {
      setMobile(true);
      setMtn(false);
      setAirtel(false);
      setGlo(false);
    }
  }, [formik]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const filteredTransaction = transactions.filter(
    (item) => item.description === "Airtime Recharge"
  );

  const last5Transactions = filteredTransaction.slice(-5).reverse();
  return (
    <>
      <HeaderTextButton headerName="Airtime Purchase" />

      <Box sx={{ flexGrow: 0, mt: "30px" }}>
        <Grid
          container
          spacing={0}
          columns={16}
          gap={{ xs: 2, md: 0 }}
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column" },
          }}
        >
          <Grid xs={16} md={8}>
            <Item
              sx={{
                overflow: "hidden",
                mr: { lg: "20px", xs: "0px" },
                p: 0,
              }}
            >
              <form
                onSubmit={formik.handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Box
                  sx={{
                    mt: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: { lg: "60px", xs: "50px" },
                      height: { lg: "60px", xs: "50px" },
                      borderRadius: "50%",
                      background: "red",
                      overflow: "hidden",
                      border: "none",
                      cursor: "pointer",
                      opacity: mtn ? 1 : 0.2,
                    }}
                  >
                    <img
                      src={mtnLogo}
                      alt="MTN"
                      style={{ width: "100%", objectFit: "contain" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: { lg: "60px", xs: "50px" },
                      height: { lg: "60px", xs: "50px" },
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "none",
                      cursor: "pointer",
                      opacity: glo ? 1 : 0.2,
                    }}
                  >
                    <img
                      src={gloLogo}
                      alt="GLO"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: { lg: "60px", xs: "50px" },
                      height: { lg: "60px", xs: "50px" },
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "none",
                      cursor: "pointer",
                      opacity: airtel ? 1 : 0.2,
                    }}
                  >
                    <img
                      src={airtelLogo}
                      alt="AIRTEL"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: { lg: "60px", xs: "50px" },
                      height: { lg: "60px", xs: "50px" },
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "none",
                      cursor: "pointer",
                      opacity: mobile ? 1 : 0.2,
                    }}
                  >
                    <img
                      src={mobileLogo}
                      alt="9 Mobile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ mx: "30px", my: "40px" }}>
                  <Box sx={{ mb: "15px" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.serviceID &&
                        Boolean(formik.errors.serviceID)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Network Provider
                      </InputLabel>
                      <Select
                        labelId="network"
                        id="serviceID"
                        name="serviceID"
                        label="Network Provider"
                        value={formik.values.serviceID}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.serviceID &&
                          Boolean(formik.errors.serviceID)
                        }
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        <MenuItem value="mtn">MTN</MenuItem>
                        <MenuItem value="glo">GLO</MenuItem>
                        <MenuItem value="airtel">AIRTEL</MenuItem>
                        <MenuItem value="etisalat">9MOBILE</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.serviceID && formik.errors.serviceID && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                      >
                        {formik.errors.serviceID}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mb: "15px" }}>
                    <InputText
                      id="phone"
                      label="Phone Number"
                      type="text"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Box>
                  <Box sx={{ mb: "15px" }}>
                    <InputText
                      id="amount"
                      label="Amount"
                      type="amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                      helperText={formik.touched.amount && formik.errors.amount}
                    />
                  </Box>
                  {isLoading ? (
                    <LoadingButton loading variant="outlined" fullWidth>
                      Submit
                    </LoadingButton>
                  ) : (
                    <ButtonComp
                      variant="contained"
                      text="Proceed"
                      type="submit"
                      fullWidth
                      disableElevation
                    />
                  )}
                </Box>
              </form>
            </Item>
          </Grid>
          <Grid xs={16} md={8}>
            <Item
              sx={{
                overflow: "hidden",
                mr: { lg: "20px", xs: "0px" },
                p: 0,
              }}
            >
              <Stack sx={{ p: "10px", minHeight: "250px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "black",
                  }}
                >
                  <Typography fontWeight={"bold"}>
                    Transactions History
                  </Typography>
                  <Link
                    to="/transactions"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <Typography fontWeight={"bold"} sx={{ cursor: "pointer" }}>
                      See All
                    </Typography>
                  </Link>
                </Box>
                <Stack sx={{ mt: "20px" }} gap={1}>
                  {last5Transactions ? (
                    last5Transactions.map((data: any, index) => (
                      <TransactionList
                        key={index}
                        amount={data.amount}
                        description={data.description}
                        date={data && data.createdAt}
                      />
                    ))
                  ) : (
                    <Typography>No transaction</Typography>
                  )}
                </Stack>
              </Stack>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RechargeAirtime;
