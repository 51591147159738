import { Grid, Stack, Typography, useTheme,Box } from "@mui/material";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import { FeatureComp } from "../Components/FeatureComp";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";

export const FeatureSection = () => {
  const theme = useTheme();

  return (
    <>
      <Stack
        id="features"
        py="30px"
        sx={{
          px: { xs: "20px", sm: "40px", md: "60px" }, // Reduced padding on mobile
        }}
      >
        <Typography
          variant="h5"
          component="div"
          fontWeight="bold"
          textAlign="center"
          sx={{
            my: "20px",
            color: theme.palette.primary.dark,
            fontSize: { xs: "1.4rem", md: "2rem" }, // Responsive font size
          }}
        >
          Why Choose Us?
        </Typography>
        <Box
          sx={{
            width: { lg: "100%", xs: "100%" },
            // maxWidth: "1200px",
            margin: "auto",
          }}
        >
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            gap={3}
            justifyContent="center"
            py={5}
          >
            <FeatureComp
              icon={<AdminPanelSettingsOutlinedIcon sx={{ color: "white" }} />}
              title="Fast Services"
              subtitle="Experience lightning-fast internet speeds that ensure seamless browsing and smooth streaming, even during peak usage."
            />
            <FeatureComp
              icon={<LocalPoliceOutlinedIcon sx={{ color: "white" }} />}
              title="Reliability"
              subtitle="Count on our rock-solid network reliability, providing you with a consistently stable internet connection wherever you go."
            />
            <FeatureComp
              icon={<CurrencyExchangeOutlinedIcon sx={{ color: "white" }} />}
              title="Flexible and Affordable"
              subtitle="Choose from our range of flexible and budget-friendly data plans tailored to meet your specific browsing needs without breaking the bank."
            />
          </Grid>
        </Box>
      </Stack>
    </>
  );
};
