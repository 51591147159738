import React, { useEffect, useState } from "react";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AllUsersTable from "../Components/Tables/AllUsersTable";
import axiosInstances from "../hooks/axiosInstances";
import { IUsers } from "../types/common";
import { InputText } from "../Components/TextFields/InputText";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import ButtonComp from "../Components/Buttons/ButtonComp";
import { useColors } from "../utils/useColor";
import { clearMessage, setLoading, setMessage, setSubmitted, setSuccess } from "../utils/formSlice";

const WalletUser = () => {
  const [userData, setUserData] = useState<IUsers[]>([]);
  const [editId, setEditId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { isLoading } = useSelector((state: any) => state.form);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    if (openDialog) {
      setEditId("");
        formik.resetForm();
    }
    setOpenDialog(!openDialog);
  };

  const handleEdit = async (id: string) => {
    setEditId(id);
    handleClickOpen();
  };

  const fetchUsers = async () => {
    await axiosInstances
      .get("/users")
      .then((res) => {
        setUserData(res.data.users);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      credit: false,
      debit: false,
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      axiosInstances
        .post(`transactions/crebit/${editId}`, value)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          dispatch(setSuccess());
          dispatch(setSubmitted());
          dispatch(setLoading(false));
          fetchUsers();
          handleClickOpen();
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          dispatch(setMessage(error.response.data.error));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  const handleCredit = () => {
    formik.setFieldValue("credit", true);
    formik.setFieldValue("debit", false);
  };
  const handleDebit = () => {
    formik.setFieldValue("debit", true);
    formik.setFieldValue("credit", false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const { success, error } = useColors();
  return (
    <>
      <HeaderTextButton headerName="User's Wallet" />

      <Box mt="40px">
        <AllUsersTable data={userData} handleEdit={handleEdit} wallet />
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleClickOpen}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>Edit Balance</DialogTitle>
        <DialogContent>
          <DialogContentText mb={"15px"}>
            Credit or debit user balance.
          </DialogContentText>
          <form onSubmit={formik.handleSubmit} noValidate>
            <InputText
              id="amount"
              label="Amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
            <Box>
              {isLoading ? (
                <LoadingButton loading variant="outlined" fullWidth>
                  Submit
                </LoadingButton>
              ) : (
                <Box
                  sx={{ display: "flex", gap: 2, width: "100%", mt: "10px" }}
                >
                  <ButtonComp
                    text="Credit"
                    type="submit"
                    variant="contained"
                    fullWidth
                    disableElevation
                    background={success.main}
                    backgroundHover={success.light}
                    onClick={() => handleCredit()}
                  />
                  <ButtonComp
                    text="Debit"
                    type="submit"
                    variant="contained"
                    fullWidth
                    disableElevation
                    background={error.main}
                    backgroundHover={error.light}
                    onClick={() => handleDebit()}
                  />
                </Box>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WalletUser;
