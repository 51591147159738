import { Box, Typography } from "@mui/material";
import ErrorImage from "../assets/Images/error404.svg";
import { FC } from "react";

interface Props {
  auth?: boolean;
}

const ErrorPage: FC<Props> = ({ auth }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      flexDirection="column"
      letterSpacing="15px"
      sx={{ mt: auth ? "85px" : "" }}
    >
      <img src={ErrorImage} alt="" width={"40%"} />
      <Typography variant="h5" component="div" mt="40px">
        Page not found
      </Typography>
    </Box>
  );
};

export default ErrorPage;
