import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { InputText } from "../TextFields/InputText";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setMessage,
  setSubmitted,
  setSuccess,
} from "../../utils/formSlice";
import AxiosInstance from "../../hooks/axiosInstances";
import { passwordValidation } from "../../utils/validationScheme";
import { LoadingButton } from "@mui/lab";

const ChangePassword = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isLoading, isSubmitted, isSuccess, ismessage } = useSelector(
    (state: any) => state.form
  );

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      current: "",
      newpass: "",
      confirmpass: "",
    },
    validationSchema: passwordValidation,
    onSubmit: (values) => {
      dispatch(setLoading(true));
      AxiosInstance.put(`api/v1/users/password`, values)
        .then((res) => {
          handleClick();
          dispatch(setMessage(res.data.message));
          dispatch(setSuccess());
          dispatch(setSubmitted());
          dispatch(setLoading(false));
          formik.resetForm();
        })
        .catch((error) => {
          handleClick();
          dispatch(setMessage(error.message));
          dispatch(setLoading(false));
        });
    },
  });

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSubmitted && isSuccess ? "success" : "error"}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {ismessage}
        </Alert>
      </Snackbar>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography fontWeight="bold">Change Password</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
            <InputText
              type="password"
              id="current"
              label="Current Password"
              value={formik.values.current}
              onChange={formik.handleChange}
              error={formik.touched.current && Boolean(formik.errors.current)}
              helperText={formik.touched.current && formik.errors.current}
            />
            <InputText
              type="password"
              id="newpass"
              label="New Password"
              value={formik.values.newpass}
              onChange={formik.handleChange}
              error={formik.touched.newpass && Boolean(formik.errors.newpass)}
              helperText={formik.touched.newpass && formik.errors.newpass}
            />
            <InputText
              type="password"
              id="confirmpass"
              label="Confirm Password"
              value={formik.values.confirmpass}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmpass && Boolean(formik.errors.confirmpass)
              }
              helperText={
                formik.touched.confirmpass && formik.errors.confirmpass
              }
            />
            {isLoading ? (
              <LoadingButton loading variant="outlined" fullWidth>
                Submit
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                sx={{
                  background: theme.palette.primary.dark,
                  my: "10px",
                }}
                disableElevation
                fullWidth
                type="submit"
              >
                Change Password
              </Button>
            )}
          </form>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ChangePassword;
