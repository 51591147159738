import "@fontsource/quicksand";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RoutesComponenet from "./routes/RoutesComponent";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { store } from "./utils/store";
import ScrollToTop from "./utils/ScrollToTop";

const theme = createTheme({
  typography: {
    fontFamily: "Quicksand",
  },
  palette: {
    primary: { main: "#4c00b0" },
    secondary: {
      main: "#CCE4FF",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <HelmetProvider>
          <BrowserRouter>
            <ScrollToTop />
            <RoutesComponenet />
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
