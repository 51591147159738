import { styled } from "@mui/material";
import { useColors } from "./useColor";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const useStyles = () => {
  const { primary } = useColors();
  return {
    text: {
      fontWeight: 600,
      color: "#5D5D5E",
      textAlign: "center",
    },
    texts: {
      mt: "5px",
      fontWeight: 600,
      color: "#5D5D5E",
      mb: "20px",
      textAlign: "justify",
    },
    smallHead: {
      fontWeight: 900,
      color: primary.dark,
      fontSize: "1.2rem",
    },
    logoSmallText: {
      color: primary.dark,
      fontWeight: 900,
    },
    welcomText: {
      fontWeight: 900,
      fontSize: "1.8em",
    },
    error404: {
      fontWeight: 900,
      color: "#5D5D5E",
      fontSize: "1.2em",
      mt: "20px",
    },
    privacyText: {
      fontWeight: 900,
      color: primary.dark,
      fontFamily: "Roboto",
      fontSize: "1.5rem",
      // pt: "50px",
    },
    textSub: {
      fontWeight: "bold",
      color: "#535354",
      my: "10px",
      textAlign: "justify",
      fontFamily: "Roboto",
    },
    tableBorder: {
      borderRight: 1,
      borderColor: "#F1F5F8",
    },
  };
};

export const UploadArea = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  border: "2px dashed #ccc",
  borderRadius: "5px",
  marginBottom: "15px",
  cursor: "pointer",
  height: "200px",
});

export const UploadFileIcon = styled(CloudUploadIcon)({
  fontSize: "64px",
  marginBottom: "10px",
});
