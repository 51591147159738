import React, { FC, useState } from "react";
import { IProductPrice } from "../../types/common";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  Box,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  TablePagination,
  styled,
  tableCellClasses,
  Theme,
} from "@mui/material";
import { formatNumber } from "../../utils/FormatedNumber";
import { useColors } from "../../utils/useColor";

interface Props {
  data: IProductPrice[];
  handleDelete: (data: any) => void;
  handleEdit: (data: any) => void;
}

const DataTable: FC<Props> = ({ data, handleEdit, handleDelete }) => {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searchText, setSearchText] = useState("");

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setPage(0);
  };

  const filteredRows = searchText
    ? data.filter(
        (row) =>
          row.serviceName
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          row.category?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.productName?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.volume?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.days?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.price?.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    : data;

  const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const { success, error } = useColors();
  return (
    <>
      <Box>
        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={handleSearch}
          style={{ marginBottom: "1rem" }}
          size="small"
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell align="left">Service</StyledTableCell>
                <StyledTableCell align="left">Category</StyledTableCell>
                <StyledTableCell align="left">Product</StyledTableCell>
                <StyledTableCell align="left">Volume/Days</StyledTableCell>
                <StyledTableCell align="left">Price</StyledTableCell>
                <StyledTableCell align="left">Variant</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(filteredRows) && filteredRows.length > 0 ? (
                filteredRows
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{row.serviceName}</TableCell>
                      <TableCell align="left">
                        {row.category ? row.category : "-"}
                      </TableCell>

                      <TableCell align="left">{row.productName}</TableCell>
                      <TableCell align="left">
                        {row.volume && row.days
                          ? `${row.volume}/${row.days} Days`
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {formatNumber(row.price)}
                      </TableCell>
                      <TableCell align="left">
                        {row.variant ? row.variant : "-"}
                      </TableCell>

                      <TableCell align="left" width="10%">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <Tooltip title="Edit">
                            <Box onClick={() => handleEdit(row._id)}>
                              <DriveFileRenameOutlineOutlinedIcon
                                sx={{ cursor: "pointer", color: success.main }}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Box onClick={() => handleDelete(row._id)}>
                              <DeleteForeverOutlinedIcon
                                sx={{ cursor: "pointer", color: error.main }}
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default DataTable;
