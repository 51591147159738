import React, { useEffect, useState } from "react";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { TransactionList } from "../Components/others/TransactionList";
import { Item } from "../utils/ItemStyle";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import ButtonComp from "../Components/Buttons/ButtonComp";
import { InputText } from "../Components/TextFields/InputText";
import { formatNumber } from "../utils/FormatedNumber";
import axiosInstances from "../hooks/axiosInstances";
import { IProducts, ISmartCard, IVariants } from "../types/common";
import { useColors } from "../utils/useColor";
import { formatTitle } from "../utils/utils";

const TvSubscription = () => {
  const { isLoading } = useSelector((state: any) => state.form);
  const [products, setProducts] = useState<IProducts[]>([]);
  const [smartCard, setSmartCard] = useState<ISmartCard>();
  const [variants, setVariants] = useState<IVariants[]>([]);
  const [isVariant, setVariant] = useState(false);
  const [isPackage, setPackage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const formik = useFormik({
    initialValues: {
      serviceID: "",
      billersCode: "",
      subscription_type: "",
      variation_code: "",
      amount: "",
    },
    onSubmit: (value) => {
      console.log(value);
    },
  });

  const fetchProducts = async () => {
    await axiosInstances
      .get("/products")
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVariantSmart = async (data: any) => {
    setIsLoad(true);
    await axiosInstances
      .post("/subs", data)
      .then((res) => {
        console.log(res.data);
        setVariants(res.data.variants.content.varations);
        setSmartCard(res.data.verifySmart.content);
        setVariant(true);
        setIsLoad(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterProducts = products.filter(
    (data) => data.serviceName === "Tv Subscription" && data.visibility === true
  );

  useEffect(() => {
    if (
      formik.values.serviceID &&
      formik.values.billersCode &&
      formik.values.billersCode.length === 10
    ) {
      const data = {
        serviceID: formik.values.serviceID,
        billersCode: formik.values.billersCode,
      };
      getVariantSmart(data);
    }

    if (formik.values.subscription_type === "change") {
      setPackage(true);
    }

    if (formik.values.variation_code) {
      const filterVariations = variants.filter(
        (data) => data.variation_code === formik.values.variation_code
      );

      formik.setFieldValue(
        "amount",
        formatNumber(parseInt(filterVariations[0].variation_amount))
      );
    }
    fetchProducts();
  }, [
    formik.values.serviceID,
    formik.values.billersCode,
    formik.values.subscription_type,
    formik.values.variation_code,
  ]);

  const { primary, secondary } = useColors();
  return (
    <>
      <HeaderTextButton headerName="Tv Subscription" />

      <Box sx={{ flexGrow: 0, mt: "30px" }}>
        <Grid
          container
          spacing={0}
          columns={16}
          gap={{ xs: 2, sm: 1, md: 0 }}
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column-reverse", sm: "row" },
          }}
        >
          <Grid xs={16} sm={8} md={8}>
            <Item
              sx={{
                overflow: "hidden",
                mr: { lg: "20px", xs: "0px" },
                p: 0,
              }}
            >
              <Stack sx={{ p: "10px", minHeight: "250px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "black",
                  }}
                >
                  <Typography fontWeight={"bold"}>
                    Transactions History
                  </Typography>
                  <Link
                    to="/transactions"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <Typography fontWeight={"bold"} sx={{ cursor: "pointer" }}>
                      See All
                    </Typography>
                  </Link>
                </Box>
                {/* <Stack sx={{ mt: "20px" }} gap={1}>
                  {last5Transactions ? (
                    last5Transactions.map((data: any, index) => (
                      <TransactionList
                        key={index}
                        amount={data.amount}
                        description={data.description}
                        date={data && data.createdAt}
                      />
                    ))
                  ) : (
                    <Typography>No transaction</Typography>
                  )}
                </Stack> */}
              </Stack>
            </Item>
          </Grid>
          <Grid xs={16} sm={8} md={8}>
            <Item
              sx={{
                overflow: "hidden",
                mr: { lg: "20px", xs: "0px" },
                p: 0,
              }}
            >
              {/* <Box
                sx={{
                  mt: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    background: "red",
                    overflow: "hidden",
                    border: "none",
                    cursor: "pointer",
                    opacity: mtn ? 1 : 0.2,
                  }}
                >
                  <img
                    src={mtnLogo}
                    alt="MTN"
                    style={{ width: "100%", objectFit: "contain" }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    border: "none",
                    cursor: "pointer",
                    opacity: glo ? 1 : 0.2,
                  }}
                >
                  <img
                    src={gloLogo}
                    alt="GLO"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    border: "none",
                    cursor: "pointer",
                    opacity: airtel ? 1 : 0.2,
                  }}
                >
                  <img
                    src={airtelLogo}
                    alt="AIRTEL"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    border: "none",
                    cursor: "pointer",
                    opacity: mobile ? 1 : 0.2,
                  }}
                >
                  <img
                    src={mobileLogo}
                    alt="9 Mobile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box> */}
              <form onSubmit={formik.handleSubmit} noValidate>
                <Box
                  sx={{
                    mx: "30px",
                    my: "40px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Box>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.serviceID &&
                        Boolean(formik.errors.serviceID)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Service
                      </InputLabel>
                      <Select
                        labelId="service"
                        id="serviceID"
                        name="serviceID"
                        label="Service"
                        value={formik.values.serviceID}
                        onChange={formik.handleChange}
                        disabled={isLoad}
                        error={
                          formik.touched.serviceID &&
                          Boolean(formik.errors.serviceID)
                        }
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {filterProducts &&
                          filterProducts.map((data, index) => (
                            <MenuItem
                              key={index}
                              value={data.product.toLowerCase()}
                            >
                              {data.product}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.touched.serviceID && formik.errors.serviceID && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                        textAlign={"left"}
                      >
                        {formik.errors.serviceID}
                      </Typography>
                    )}
                  </Box>
                  <InputText
                    id="billersCode"
                    label="IUC Number"
                    value={formik.values.billersCode}
                    onChange={formik.handleChange}
                    disabled={isLoad}
                    error={
                      formik.touched.billersCode &&
                      Boolean(formik.errors.billersCode)
                    }
                    helperText={
                      formik.touched.billersCode && formik.errors.billersCode
                    }
                  />
                  {isVariant ? (
                    <Box
                      sx={{
                        minHeight: "200px",
                        backgroundColor: secondary.light,
                        p: 2,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={5}
                          sx={{
                            textAlign: "left",
                            gap: 2,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography>Name</Typography>
                          <Typography>Current Bouquet(s)</Typography>
                          <Typography>Due Date</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          sx={{
                            textAlign: "left",
                            gap: 2,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography>{smartCard?.Customer_Name}</Typography>
                          <Typography>
                            {formatTitle(smartCard?.Current_Bouquet)}
                          </Typography>
                          <Typography>{smartCard?.Due_Date}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    isLoad && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <CircularProgress sx={{ color: primary.dark }} />
                        <Typography>Loading.....</Typography>
                      </Box>
                    )
                  )}

                  <Box>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.subscription_type &&
                        Boolean(formik.errors.subscription_type)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        What do you want to do?
                      </InputLabel>
                      <Select
                        id="subscription_type"
                        name="subscription_type"
                        label="What do you want to do?"
                        value={formik.values.subscription_type}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.subscription_type &&
                          Boolean(formik.errors.subscription_type)
                        }
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        <MenuItem value="renew">Renew Current Bouquet</MenuItem>
                        <MenuItem value="change">Change Bouquet</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.subscription_type &&
                      formik.errors.subscription_type && (
                        <Typography
                          color="error"
                          fontSize={"0.75rem"}
                          ml={"14px"}
                          textAlign={"left"}
                        >
                          {formik.errors.subscription_type}
                        </Typography>
                      )}
                  </Box>

                  {isPackage && (
                    <Box>
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.variation_code &&
                          Boolean(formik.errors.variation_code)
                        }
                      >
                        <InputLabel id="demo-select-small-label">
                          Packages
                        </InputLabel>
                        <Select
                          id="variation_code"
                          name="variation_code"
                          label="Packages"
                          value={formik.values.variation_code}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.variation_code &&
                            Boolean(formik.errors.variation_code)
                          }
                          sx={{
                            textAlign: "left",
                          }}
                        >
                          {variants.map((data, index) => (
                            <MenuItem key={index} value={data.variation_code}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.touched.variation_code &&
                        formik.errors.variation_code && (
                          <Typography
                            color="error"
                            fontSize={"0.75rem"}
                            ml={"14px"}
                            textAlign={"left"}
                          >
                            {formik.errors.variation_code}
                          </Typography>
                        )}
                    </Box>
                  )}

                  <InputText
                    id="amount"
                    label="Amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    disabled
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                  {/* 
                  
                  
                  <Box sx={{ mb: "15px" }}>
                    <InputText
                      id="number"
                      label="Phone Number"
                      value={formik.values.number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.number && Boolean(formik.errors.number)
                      }
                      helperText={formik.touched.number && formik.errors.number}
                    />
                  </Box> */}

                  {isLoading ? (
                    <LoadingButton loading variant="outlined" fullWidth>
                      Submit
                    </LoadingButton>
                  ) : (
                    <ButtonComp
                      variant="contained"
                      text="Proceed"
                      type="submit"
                      fullWidth
                      disableElevation
                    />
                  )}
                </Box>
              </form>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TvSubscription;
