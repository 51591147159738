/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Item } from "../utils/ItemStyle";
import { InputText } from "../Components/TextFields/InputText";
import ButtonComp from "../Components/Buttons/ButtonComp";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import {
  clearMessage,
  setLoading,
  setMessage,
  setSubmitted,
  setSuccess,
} from "../utils/formSlice";
import axiosInstances from "../hooks/axiosInstances";
import { IServices } from "../types/common";
import ServicesTable from "../Components/Tables/ServicesTable";
import ActionModal from "../Components/Modals/ActionModal";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const Services = () => {
  const { isLoading } = useSelector((state: any) => state.form);

  const [open, setOpen] = useState(false);
  const [actionMessage, setActionMessage] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [editId, setEditId] = useState("");
  const dispatch = useDispatch();
  const [services, setServices] = useState<IServices[]>([]);

  const handleClose = () => {
    setEditId("");
    setDeleteId("");
    setOpen(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      visibility: false,
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));

      if (editId) {
        axiosInstances
          .put(`services/${editId}`, value)
          .then((res) => {
            dispatch(setMessage(res.data.message));
            dispatch(setSuccess());
            dispatch(setSubmitted());
            dispatch(setLoading(false));
            formik.resetForm();
            fetchServices();
            handleClose()
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          })
          .catch((error) => {
            dispatch(setMessage(error.response.data.error));
            dispatch(setLoading(false));
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          });
      } else {
        axiosInstances
          .post("services", value)
          .then((res) => {
            dispatch(setMessage(res.data.message));
            dispatch(setSuccess());
            dispatch(setSubmitted());
            dispatch(setLoading(false));
            formik.resetForm();
            fetchServices();
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          })
          .catch((error) => {
            dispatch(setMessage(error.response.data.error));
            dispatch(setLoading(false));
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          });
      }
    },
  });

  const fetchServices = async () => {
    await axiosInstances
      .get("services")
      .then((res) => {
        setServices(res.data.services);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = (id: string) => {
    setEditId(id);
  };
  const handleDelete = (id: string) => {
    setActionMessage("Are you sure you want to delete this service?");
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = async () => {
    dispatch(setLoading(true));
    await axiosInstances
      .delete(`services/${deleteId}`)
      .then((res) => {
        dispatch(setMessage(res.data.message));
        fetchServices();
        dispatch(setSuccess());
        dispatch(setSubmitted());
        dispatch(setLoading(false));
        handleClose();
        setTimeout(() => {
          dispatch(clearMessage());
        }, 3000);
      })
      .catch((error) => {
        dispatch(setMessage(error.response.data.error));
        dispatch(setLoading(false));
        setTimeout(() => {
          dispatch(clearMessage());
        }, 3000);
      });
  };

  const setMultipleValues = (values: { [key: string]: any }) => {
    Object.keys(values).forEach((key) => {
      formik.setFieldValue(key, values[key]);
    });
  };

  const fetchService = async (id: string) => {
    try {
      await axiosInstances.get(`services/${id}`).then((res) => {
        const data = res.data as IServices;
        setMultipleValues({
          title: data.title,
          visibility: data.visibility,
        });
      });
    } catch (error: any) {
      dispatch(setMessage(error.response.data.error));
      dispatch(setLoading(false));
      setTimeout(() => {
        dispatch(clearMessage());
      }, 3000);
    }
  };

  useEffect(() => {
    fetchServices();

    if (editId) {
      fetchService(editId);
    }
  }, [editId]);
  return (
    <>
      <HeaderTextButton headerName="All Services" />

      <ActionModal
        open={open}
        handleClose={handleClose}
        status="error"
        message={actionMessage}
        confirmAction={() => confirmDelete()}
      />

      <Box sx={{ flexGrow: 0, mt: "20px" }}>
        <Grid
          container
          spacing={0}
          columns={16}
          gap={{ xs: 2, md: 2 }}
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column" },
            justifyContent: "space-between",
          }}
        >
          <Grid md={9} xs={16}>
            {/* <Item
              sx={{
                overflow: "hidden",
              }}
            > */}
            <Box>
              <ServicesTable
                data={services}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            </Box>
            {/* </Item> */}
          </Grid>
          <Grid xs={16} md={6}>
            <Item
              sx={{
                overflow: "hidden",
                mr: { lg: "20px", xs: "0px" },
                p: 0,
              }}
            >
              <Stack sx={{ p: "10px", minHeight: "200px", mt: "10px", gap: 2 }}>
                <form
                  onSubmit={formik.handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <Typography sx={{ mb: "10px", fontWeight: "bold" }}>
                    {editId ? "Update" : "Create"} Service
                  </Typography>
                  <InputText
                    id="title"
                    label="Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                  <Box>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.visibility &&
                        Boolean(formik.errors.visibility)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Visibility
                      </InputLabel>
                      <Select
                        labelId="visibility"
                        id="visibility"
                        name="visibility"
                        label="Visibility"
                        value={formik.values.visibility}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.visibility &&
                          Boolean(formik.errors.visibility)
                        }
                        sx={{ textAlign: "left" }}
                      >
                        <MenuItem value="true">True</MenuItem>
                        <MenuItem value="false">False</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.visibility && formik.errors.visibility && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                      >
                        {formik.errors.visibility}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mt: "15px" }}>
                    {isLoading ? (
                      <LoadingButton loading variant="outlined" fullWidth>
                        Submit
                      </LoadingButton>
                    ) : (
                      <Box sx={{ display: "flex", gap: 1.5 }}>
                        <ButtonComp
                          fullWidth
                          text="Submit"
                          type="submit"
                          variant="contained"
                          mt="10px"
                        />
                        {editId && (
                          <Button variant="outlined" onClick={handleClose}>
                            <ClearOutlinedIcon />
                          </Button>
                        )}
                      </Box>
                    )}
                  </Box>
                </form>
              </Stack>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Services;
