import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import React, { FC, useEffect, useState } from "react";
import axiosInstances from "../../hooks/axiosInstances";
import { IPinsType } from "../../types/common";
import { fetchImage } from "../../utils/utils";

interface Props {
  id: string;
}

const ScratchCard: FC<Props> = ({ id }) => {
  const [pins, setPins] = useState<IPinsType | undefined>();

  const fetchPins = async (id: string) => {
    await axiosInstances
      .get(`pin/single/${id}`)
      .then((res) => {
        setPins(res.data.pin);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPins(id);
  }, [id]);

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#fff",
      padding: 20,
      position: "relative",
    },
    image: {
      width: 45,
      height: "auto",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerText: {
      fontSize: "11px",
    },
    cardContent: {
      flexDirection: "row",
      marginTop: "15px",
      border: 1,
      borderColor: "#E4E4E4",
      padding: "10px",
    },
  });

  const image = fetchImage(pins?.cardType);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {pins?.pin.map((data) => (
          <>
            <View style={styles.header}>
              <View>
                <Image src={image} style={styles.image} />
              </View>
              <View style={styles.headerText}>
                <Text style={{ fontWeight: "bold" }}>Powered by</Text>
                <Text>www.zudovite.com.ng</Text>
              </View>
            </View>
            <View style={styles.cardContent}>
              <View style={{ width: "50%" }}>
                <Text style={{ fontSize: "12px" }}>{data.pin}</Text>
              </View>
              <View
                style={{
                  width: "50%",
                  borderLeft: 1,
                  borderColor: "#E4E4E4",
                  paddingLeft: "10px",
                }}
              >
                <Text style={{ fontSize: "12px" }}>{data.seria_no}</Text>
              </View>
            </View>
          </>
        ))}
      </Page>
    </Document>
  );
};

export default ScratchCard;
