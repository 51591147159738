import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useColors } from "../utils/useColor";
import { useStyles } from "../utils/useStyles";
import { Link } from "react-router-dom";

const RefundPolicy = () => {
  const { primary } = useColors();
  const style = useStyles();
  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          mt: "85px",
          //   background: "red",
        }}
      >
        <Box>
          <Typography
            variant="h2"
            fontWeight={900}
            sx={{
              color: primary.dark,
              fontFamily: "Roboto",
              fontSize: { xs: "2rem", lg: "1.8rem" },
              mb: "30px",
            }}
          >
            Refund Policy
          </Typography>
        </Box>
        <Box>
          {/* <Typography sx={style.texts}>
          Effective Date: 1st July, 2024
        </Typography> */}
          <Typography sx={style.texts}>
            At ZudoData, customer satisfaction is our top priority. This Refund
            Policy outlines the conditions under which refunds may be granted
            for our services, including mobile internet data subscriptions, bill
            payments, virtual numbers, virtual account numbers and more.
          </Typography>
          {/* First */}
          <Typography sx={style.privacyText}>
            General Refund Conditions
          </Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                Eligibility: Refunds are only available for transactions that
                meet the criteria outlined in this policy. To request a refund,
                you must provide proof of purchase and a valid reason for the
                refund.
              </li>
              <li>
                Refund Request Period: Refund requests must be submitted within
                48 hours of the transaction date. Refunds requested after this
                period may not be processed.
              </li>
            </ul>
          </Typography>
          {/* Second */}
          <Typography sx={style.privacyText}>
            Non-Refundable Services
          </Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                Data Subscriptions: Once a mobile internet data subscription has
                been successfully processed and activated, it is non-refundable.
                Please ensure you select the correct data plan before completing
                your purchase.
              </li>
              <li>
                Bill Payments: Payments made for bills are non-refundable once
                they have been processed and confirmed. It is your
                responsibility to verify the accuracy of the bill payment
                details before submission.
              </li>
              <li>
                Virtual Numbers: Purchases of virtual numbers are non-refundable
                once the number has been issued and activated.
              </li>
            </ul>
          </Typography>
          {/* Third */}
          <Typography sx={style.privacyText}>
            Refunds for Technical Errors
          </Typography>
          <Typography sx={style.texts}>
            If a technical error on our platform causes an incorrect
            transaction, such as an overcharge or a failure to deliver the
            purchased service, you may be eligible for a refund. Please contact
            our customer support team immediately to resolve the issue.
          </Typography>
          {/* Fourth */}
          <Typography sx={style.privacyText}>
            How to Request a Refund
          </Typography>
          <Typography sx={style.texts}>
            <ul>
              <li>
                Contact Us: To request a refund, please contact our customer
                support team at [Insert Contact Information] with the following
                details:
              </li>
              <ul>
                <li>Your full name</li>
                <li>Transaction ID</li>
                <li>Date of the transaction</li>
                <li>Reason for the refund request</li>
              </ul>
              <li>
                Processing Time: Refund requests will be reviewed and processed
                within two business days of receipt. If your request is
                approved, the refund will be issued to the original payment
                method.
              </li>
            </ul>
          </Typography>

          {/* Fifth */}
          <Typography sx={style.privacyText}>Refund Processing</Typography>

          <Typography sx={style.texts}>
            <ul>
              <li>
                Refund Method: Approved refunds will be credited back to the
                original payment method used during the transaction. The time it
                takes for the refund to appear in your account may vary
                depending on your payment provider.
              </li>
              <li>
                Partial Refunds: In certain cases, partial refunds may be
                granted, depending on the nature of the service and the reason
                for the refund request.
              </li>
            </ul>
          </Typography>
          {/* Sixth */}
          <Typography sx={style.privacyText}>
            Changes to This Refund Policy
          </Typography>
          <Typography sx={style.texts}>
            ZudoData reserves the right to modify this Refund Policy at any
            time. Any changes will be posted on this page, and the effective
            date will be updated accordingly. It is your responsibility to
            review this policy periodically.
          </Typography>

          {/* Nineth */}
          <Typography sx={style.privacyText}>Contact Us</Typography>
          <Typography sx={style.texts}>
            If you have any questions or concerns about this Privacy Policy,
            please contact us <Link to="/contact-us">here</Link>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default RefundPolicy;
