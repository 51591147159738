/* eslint-disable react-hooks/exhaustive-deps */
import { styled, Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../lib/useUser";
import axiosInstances from "../hooks/axiosInstances";
import { setSuccess, setMessage, clearMessage } from "../utils/formSlice";

const Overlay = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
});

const KycPage = () => {
  const location = useLocation();
  const updateUser = useUser((state) => state.updateUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const bvn = queryParams.get("bvn");
      const nin = queryParams.get("nin");
      const bankCode = queryParams.get("bankCode");
      const accountNumber = queryParams.get("accountNumber");

      const value = {
        bvn: bvn,
        nin: nin,
        bankCode: bankCode,
        accountNumber: accountNumber,
      };

      await axiosInstances
        .post("/users/kyc/verify", value)
        .then((res) => {
          updateUser(res.data.user);
          dispatch(setSuccess());
          dispatch(setMessage(res.data.message));
          navigate("/profile");
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          dispatch(setMessage(error.response.data.error));
        //   navigate("/profile");
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    };

    fetchData();
  }, [location.search]);
  return (
    <>
      <Overlay>
        <CircularProgress color="primary" />
      </Overlay>
    </>
  );
};

export default KycPage;
