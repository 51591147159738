import React, { FC, useState } from "react";
import { ITransactions } from "../../types/common";
import {
  Box,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Tooltip,
  TablePagination,
  styled,
  tableCellClasses,
  Theme,
} from "@mui/material";
import { convertTimestampToDate } from "../../utils/utils";
import { useColors } from "../../utils/useColor";
import { Download } from "@mui/icons-material";
import { formatNumber } from "../../utils/FormatedNumber";
import useUser from "../../lib/useUser";

interface Props {
  data: ITransactions[];
  handleEdit: (id: string) => void;
}

const TransactionTable: FC<Props> = ({ data, handleEdit }) => {
  const user = useUser((state) => state.user);
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searchText, setSearchText] = useState("");

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setPage(0);
  };

  const filteredRows = searchText
    ? data.filter(
        (row) =>
          row.amount
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          (searchText.toLowerCase() === "successful" && row.status === true) ||
          (searchText.toLowerCase() === "failed" && row.status === false) ||
          row.description?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.userEmail?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.tr_ref?.toLowerCase().includes(searchText.toLowerCase())
      )
    : data;

  const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const { success, error } = useColors();
  return (
    <>
      <Box>
        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={handleSearch}
          style={{ marginBottom: "1rem" }}
          size="small"
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell align="left">Amount</StyledTableCell>
                {user.role === "ADMIN" && (
                  <StyledTableCell align="left">Email</StyledTableCell>
                )}

                <StyledTableCell align="left">Action</StyledTableCell>
                <StyledTableCell align="left">Tx Ref</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="left">Receipt</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(filteredRows) && filteredRows.length > 0 ? (
                filteredRows
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">
                        {formatNumber(row.amount)}
                      </TableCell>
                      {user.role === "ADMIN" && (
                        <TableCell align="left">{row.userEmail}</TableCell>
                      )}

                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell align="left">{row.tr_ref}</TableCell>
                      <TableCell align="center" width="10%">
                        {row.status === false ? (
                          <Box
                            sx={{
                              background: error.light,
                              width: { lg: "80%", xs: "100%" },
                              borderRadius: "20px",
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              Failed
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              background: success.light,
                              width: "80%",
                              borderRadius: "20px",
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              Successful
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {convertTimestampToDate(row.createdAt)}
                      </TableCell>

                      <TableCell align="left" width="10%">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Tooltip title="Download receipt">
                            <Box onClick={() => handleEdit(row.id)}>
                              <Download
                                sx={{ cursor: "pointer", color: "green" }}
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default TransactionTable;
