import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { FC } from 'react'

interface PasswordResetProps {
  NavigatePassword: () => void
}

export const PasswordReset: FC<PasswordResetProps> = ({ NavigatePassword }) => {
  const theme = useTheme()

  return (
    <>
      <Box pb='30px' textAlign='center'>
        <Typography variant='h6' fontWeight='bold' component='div'>
          Password Reset
        </Typography>
      </Box>
      <Box sx={{ width: '80%', px: '20px' }}>
        <TextField
          id='email'
          label='Email Address'
          fullWidth
          sx={{
            '& input': {
              padding: '14px' // Adjust the padding to change the height
            },
            mb: '10px'
          }}
        />

        <Button
          variant='contained'
          sx={{ background: theme.palette.primary.dark, my: '10px' }}
          disableElevation
          fullWidth
        >
          Reset Password
        </Button>

        <Typography
          variant='button'
          textAlign='right'
          component='div'
          sx={{ cursor: 'pointer' }}
          onClick={NavigatePassword}
        >
          Return to login
        </Typography>
      </Box>
    </>
  )
}
