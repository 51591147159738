import { useTheme } from "@mui/material";

export const useColors = () => {
  const theme = useTheme();

  return {
    primary: theme.palette.primary,
    secondary: theme.palette.secondary,
    success: theme.palette.success,
    error: theme.palette.error
  };
};
