import { Snackbar, Alert } from "@mui/material";
import React, { FC, useEffect, useState } from "react";

interface SnackBarProps {
  isSubmitted: boolean;
  isSuccess: boolean;
  ismessage: string;
}
const SnackBar: FC<SnackBarProps> = ({ isSubmitted, isSuccess, ismessage }) => {
  const [open, setOpen] = useState<boolean>(Boolean(ismessage));

  useEffect(() => {
    if (ismessage) {
      setOpen(true);
    }
  }, [ismessage]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      {ismessage && (
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={isSubmitted && isSuccess ? "success" : "error"}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {ismessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackBar;
