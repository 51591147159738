import { Box, Divider, Typography } from "@mui/material";
import React, { FC } from "react";
import { MdAttachMoney } from "react-icons/md";
import { formatNumber } from "../../utils/FormatedNumber";
import { formatDistanceToNow } from "date-fns";
import { useColors } from "../../utils/useColor";

interface TransactionsProps {
  amount: number;
  description: string;
  date: string;
}

export const TransactionList: FC<TransactionsProps> = ({
  amount,
  description,
  date,
}) => {
  const { success, error } = useColors();

  const pastDate = new Date(`${date}`);
  // const pastDate = new Date('2023-12-20T12:00:00Z');
  const timeAgo = formatDistanceToNow(pastDate, {
    addSuffix: true,
    includeSeconds: true,
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "35px",
              height: "35px",
              background:
                description === "Deposit" ? success.light : error.light,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexWrap: "wrap",
              mr: "20px",
            }}
          >
            <MdAttachMoney size={20} color="white" />
          </Box>
          <Box
            sx={{
              "& > :not(:last-child)": {
                marginBottom: "-6px",
              },
              textAlign: "left",
            }}
          >
            <Typography fontWeight={"bold"}>{description}</Typography>
            <Typography fontSize={"12px"}>{timeAgo && timeAgo}</Typography>
          </Box>
        </Box>
        <Typography fontWeight={"bold"}>
          {description === "Deposit" ? "+" : "-"}
          {formatNumber(amount)}
        </Typography>
      </Box>
      <Divider sx={{ borderColor: "#f1f5f8", mt: "5px" }} />
    </>
  );
};
