import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ButtonComp from "../Buttons/ButtonComp";
import { useColors } from "../../utils/useColor";
import { useStyles } from "../../utils/useStyles";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

interface ActionModalProps {
  open: boolean;
  handleClose: () => void;
  confirmAction: () => void;
  status: "error" | "success";
  message: string;
  actionText?: string;
}

const ActionModal: FC<ActionModalProps> = ({
  open,
  handleClose,
  confirmAction,
  status,
  message,
  actionText,
}) => {
  const style = useStyles();

  const { success, error } = useColors();

  const { isLoading } = useSelector((state: any) => state.form);
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open}
        onClose={(event, reason) => {
          // Prevent closing for reasons other than 'close button'
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            return;
          }
        }}
        disableEscapeKeyDown
      >
        <DialogContent>
          <DialogContentText>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "45px",
                  height: "45px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: "20px",
                }}
              >
                {status === "error" ? (
                  <HighlightOffOutlinedIcon
                    color="error"
                    fontSize="inherit"
                    sx={{ fontSize: "50px" }}
                  />
                ) : (
                  <CheckCircleOutlinedIcon
                    color="success"
                    fontSize="inherit"
                    sx={{ fontSize: "50px" }}
                  />
                )}
              </Box>

              <Typography sx={style.text}>{message}</Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            displaay: "flex",
            justifyContent: "center",
            width: "70%",
            alignSelf: "center",
            gap: 2,
            mb: "20px",
          }}
        >
          {status === "error" &&
            (isLoading ? (
              <LoadingButton loading variant="outlined" fullWidth>
                Submit
              </LoadingButton>
            ) : (
              <ButtonComp
                variant="contained"
                text="Confirm"
                type="button"
                disableElevation
                onClick={confirmAction}
                sx={{
                  background: success.dark,
                  "&:hover": {
                    background: success.light,
                  },
                }}
              />
            ))}
          {isLoading ? (
            <LoadingButton loading variant="outlined" fullWidth>
              Submit
            </LoadingButton>
          ) : (
            <ButtonComp
              variant="contained"
              text="close"
              disableElevation
              type="button"
              onClick={handleClose}
              sx={{
                background: error.dark,
                "&:hover": {
                  background: error.light,
                },
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActionModal;
