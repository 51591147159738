/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Stack,
} from "@mui/material";
import axiosInstances from "../hooks/axiosInstances";
import { IProducts, IServices } from "../types/common";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import ButtonComp from "../Components/Buttons/ButtonComp";
import { InputText } from "../Components/TextFields/InputText";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../utils/formSlice";
import ProductsTable from "../Components/Tables/ProductsTable";
import ActionModal from "../Components/Modals/ActionModal";

const Products = () => {
  const { isLoading } = useSelector((state: any) => state.form);
  const [openDialog, setOpenDialog] = useState(false);
  const [editId, setEditId] = useState("");
  const [services, setServices] = useState<IServices[]>([]);
  const [isCategory, setCategory] = useState(false);
  const [products, setProducts] = useState<IProducts[]>([]);
  const [open, setOpen] = useState(false);
  const [actionMessage, setActionMessage] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    if (openDialog) {
      setEditId("");
      formik.resetForm();
    }
    setOpenDialog(!openDialog);
  };

  const handleClose = ()=>{
    setDeleteId("");
    setOpen(false);
  }

  const fetchServices = async () => {
    await axiosInstances
      .get("services")
      .then((res) => {
        setServices(res.data.services);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      service: "",
      category: "",
      product: "",
      visibility: false,
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      if (editId) {
        axiosInstances
          .put(`products/${editId}`, value)
          .then((res) => {
            dispatch(setMessage(res.data.message));
            dispatch(setSuccess());
            dispatch(setSubmitted());
            dispatch(setLoading(false));
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          })
          .catch((error) => {
            dispatch(setMessage(error.response.data.error));
            dispatch(setLoading(false));
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          });
      } else {
        axiosInstances
          .post("products", value)
          .then((res) => {
            dispatch(setMessage(res.data.message));
            dispatch(setSuccess());
            dispatch(setSubmitted());
            dispatch(setLoading(false));
            formik.resetForm();
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          })
          .catch((error) => {
            dispatch(setMessage(error.response.data.error));
            dispatch(setLoading(false));
            setTimeout(() => {
              dispatch(clearMessage());
            }, 3000);
          });
      }
    },
  });

  const confirmDelete = async ()=>{
    dispatch(setLoading(true));
    await axiosInstances
      .delete(`products/${deleteId}`)
      .then((res) => {
        dispatch(setMessage(res.data.message));
        fetchProducts();
        dispatch(setSuccess());
        dispatch(setSubmitted());
        dispatch(setLoading(false));
        handleClose();
        setTimeout(() => {
          dispatch(clearMessage());
        }, 3000);
      })
      .catch((error) => {
        dispatch(setMessage(error.response.data.error));
        dispatch(setLoading(false));
        setTimeout(() => {
          dispatch(clearMessage());
        }, 3000);
      });
  }

  const filterService = services.filter((data) => data.visibility === true);

  const fetchProducts = async () => {
    await axiosInstances
      .get("products")
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setMultipleValues = (values: { [key: string]: any }) => {
    Object.keys(values).forEach((key) => {
      formik.setFieldValue(key, values[key]);
    });
  };

  const fetchProduct = async (id: string) => {
    await axiosInstances
      .get(`products/${id}`)
      .then((res) => {
        const data = res.data.product;
        setMultipleValues({
          service: data.service,
          category: data.category,
          product: data.product,
          visibility: data.visibility,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = (id: string) => {
    handleClickOpen();
    setEditId(id);
  };

  const handleDelete = (id: string) => {
    setActionMessage("Are you sure you want to delete this Product?");
    setOpen(true);
    setDeleteId(id);
  };

  useEffect(() => {
    fetchServices();
    fetchProducts();
    if (formik.values.service) {
      const filterData = services.filter(
        (data) => data._id === formik.values.service
      );
      if (filterData && filterData[0].title === "Data") {
        setCategory(true);
      } else {
        setCategory(false);
      }
    }

    if (editId) {
      fetchProduct(editId);
    }
  }, [formik.values.service, editId]);
  return (
    <>
      <HeaderTextButton
        headerName="Products"
        buttonName="Add New"
        onclickfunction={handleClickOpen}
      />

      <ActionModal
        open={open}
        handleClose={handleClose}
        status="error"
        message={actionMessage}
        confirmAction={() => confirmDelete()}
      />

      <Box>
        <ProductsTable
          data={products}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </Box>

      <Dialog open={openDialog} onClose={handleClickOpen}>
        <DialogTitle sx={{ fontWeight: "bold" }}>
          {editId ? "Update Product" : "New Product"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={"15px"}>
            Enter a concise yet descriptive product details to create record.
          </DialogContentText>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Stack sx={{ gap: 2 }}>
              <Box>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.service && Boolean(formik.errors.service)
                  }
                >
                  <InputLabel id="demo-select-small-label">Service</InputLabel>
                  <Select
                    labelId="Service"
                    id="service"
                    name="service"
                    label="Service"
                    value={formik.values.service}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.service && Boolean(formik.errors.service)
                    }
                    sx={{ textAlign: "left" }}
                  >
                    {filterService.map((data, index) => (
                      <MenuItem key={index} value={data._id}>
                        {data.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.touched.service && formik.errors.service && (
                  <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                    {formik.errors.service}
                  </Typography>
                )}
              </Box>
              {isCategory && (
                <Box>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                  >
                    <InputLabel id="demo-select-small-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="category"
                      id="category"
                      name="category"
                      label="category"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                      sx={{ textAlign: "left" }}
                    >
                      <MenuItem value="MTN">MTN</MenuItem>
                      <MenuItem value="GLO">GLO</MenuItem>
                      <MenuItem value="AIRTEL">AIRTEL</MenuItem>
                      <MenuItem value="9MOBILE">9MOBILE</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.category && formik.errors.category && (
                    <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                      {formik.errors.category}
                    </Typography>
                  )}
                </Box>
              )}
              <InputText
                id="product"
                label="Product"
                value={formik.values.product}
                onChange={formik.handleChange}
                error={formik.touched.product && Boolean(formik.errors.product)}
                helperText={formik.touched.product && formik.errors.product}
              />
              <Box>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.visibility &&
                    Boolean(formik.errors.visibility)
                  }
                >
                  <InputLabel id="demo-select-small-label">
                    Visibility
                  </InputLabel>
                  <Select
                    labelId="visibility"
                    id="visibility"
                    name="visibility"
                    label="Visibility"
                    value={formik.values.visibility}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.visibility &&
                      Boolean(formik.errors.visibility)
                    }
                    sx={{ textAlign: "left" }}
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.visibility && formik.errors.visibility && (
                  <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                    {formik.errors.visibility}
                  </Typography>
                )}
              </Box>
              <Box>
                {isLoading ? (
                  <LoadingButton loading variant="outlined" fullWidth>
                    Submit
                  </LoadingButton>
                ) : (
                  <ButtonComp
                    text={editId ? "Update" : "Submit"}
                    type="submit"
                    variant="contained"
                    fullWidth
                    disableElevation
                  />
                )}
              </Box>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Products;
