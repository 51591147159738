import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import LandingImag from "../assets/Images/landingimg.png";
import { Link } from "react-router-dom";

export const HeroSection = () => {
  const theme = useTheme();
  const teaxt = theme.palette.primary;
  return (
    <>
      <Stack
        sx={{
          display: "flex",
          flexDirection: {
            md: "row",
            xs: "column-reverse",
            sm: "column-reverse",
          },
          justifyContent: { md: "space-between", xs: "center", sm: "center" },
          alignContent: "center",
          flexWrap: "wrap",
          bgcolor: "#fff",
          pb: "10px",
          px: { md: "170px", sm: "40px", xs: "20px" }, // Responsive padding
          height: { md: "65vh", sm: "100vh", xs: "auto" }, // Let height be flexible on xs
          overflow: "hidden",
          pt: { md: "40px", xs: "60px" },
        }}
      >
        <Box
          width={{ md: "50%", sm: "100%", xs: "100%" }} // Full width on smaller screens
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Typography
            variant="h4"
            component="div"
            fontWeight="bold"
            sx={{ fontFamily: "Roboto", my: "20px", color: teaxt.dark }}
          >
            Browse Without Limits
          </Typography>
          <Typography variant="h5" component="div" fontWeight="bold" my="20px">
            Unleash the Power of Unlimited Mobile Data.
          </Typography>
          <Typography sx={{ textAlign: { sm: "justify", xs: "center" } }}>
            Experience the freedom of uninterrupted browsing with our
            cutting-edge mobile internet data plans. Say goodbye to buffering
            and hello to limitless possibilities. Join us today and unlock a
            world of connectivity!
          </Typography>
          <Box sx={{ my: "20px" }}>
            <Link to="/signup">
              <Button
                variant="contained"
                sx={{ background: teaxt.dark, fontWeight: "bold", mr: "10px" }}
                disableElevation
              >
                Get Started
              </Button>
            </Link>
            <Link to="/contact-us">
              <Button
                variant="contained"
                sx={{ background: teaxt.dark, fontWeight: "bold" }}
                disableElevation
              >
                Contact Us
              </Button>
            </Link>
          </Box>
        </Box>

        <Box
          width={{ md: "50%", sm: "100%", xs: "100%" }}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: { md: "right", xs: "center" }, // Center image on mobile
            alignContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              background: teaxt.dark,
              height: "270px",
              width: "250px",
              borderRadius: "5px",
              mr: { md: "50px", xs: "0" },
              ml: { xs: "0%", md: "0" }, // Center on mobile
              mt: "30px",
              mb: { xs: "50px" },
              transform: "rotateZ(10deg)",
              position: "relative",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                background: teaxt.main,
                transform: "rotateZ(-10deg)",
                width: "100%", // Use full width here
                borderRadius: "5px",
                display: "flex",
                flexWrap: "wrap",
                alignContent: "flex-end",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <img
                src={LandingImag}
                alt="Hero Section"
                style={{
                  height: "130%",
                  alignSelf: "center",
                  marginLeft: "35px", // Adjust alignment for center on mobile
                }}
              />
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
};
